:root {
    --highlight: #8FD243;
    // --faded: #1E668A;
    --faded: #578399;
    --dark: #09131D;
    --base: #152E46;
    --error: #FF004D;
    --ok: #12ADBE;
}

.button-edit,
.button-remove {
    background-color: transparent;
    border: none;

    &:hover path {
        fill: var(--highlight);
    }
}

.home-section {
    .widget-list {
        min-height: 76vh;
    }

    .widgets-slider {
        display:none;
        &.active {
            display:block;
        }
    }

    .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 48px;
        color: #FFFFFF;
    }

    .text-description {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        color: #FFFFFF;
    }

    .text-small {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #fff;
        cursor: pointer;

        &:hover {
            svg path {
                fill: var(--highlight);
            }
        }
    }

    .order-count {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 65px;
        line-height: 79px;
        color: var(--highlight);
    }

    .order-number {
        color: var(--highlight);
        font-weight: bold;
    }

    .widget {
        width: 294px;
    }

    .quote-count,
    .job-count {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 65px;
        line-height: 79px;
        color: var(--faded);
    }

    .expire {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 23px;
        color: var(--error);
    }
}

.error-section {
    p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        line-height: 106px;
        color: #fff;

       &.text-oops {
            font-size: 87px;
        }

        &.text-page {
            font-size: 18px;
            line-height: 24px;
        }

        &.text-error {
            font-size: 106px;
        }
    }

    .form-button {
        height: 60px;
        font-size: 15px;
        line-height: 19px;
    }
}

.print-section {
    color: #fff;
    .card {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
        border-radius: 14px;
        background-color: transparent;
        border: none;
    }
    .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    }
}

@media screen and (max-width: 766px) {
    .home-section {
        .pagination {
            display: none;
        };

        .widgets-slider {
            display: block;
        }
    }
}