.quotes-chart {

    .total-number {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 65px;
        line-height: 79px;
        color: #FFFFFF;
        cursor: pointer;
    }

    .text-chart {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    }
    .chart-all {
        height:120px;
        background-color: var(--faded);
        transform: rotateX(180deg);
        cursor: pointer;

        .full-filled {
            background-color: var(--highlight);
        }
    }
}