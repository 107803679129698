.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;

  &.status-0 {
    background-color: #f8f9fa;
    color: #6c757d;
  }

  &.status-1 {
    background-color: #cfe2ff;
    color: #0d6efd;
  }

  &.status-2 {
    background-color: #d1e7dd;
    color: #198754;
  }
}

// Add more styles as needed
.order-detail {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #FFFFFF;

  .id {
    font-size: 31px;
    line-height: 37px;
  }

  .quote {
    font-size: 19px;
    line-height: 23px;
  }

  .order-details,
  .job-details {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: var(--faded);
  }

  .data {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--faded);
  }

  .value {
    font-size: 22px;
    line-height: 29px;
    color: #fff;

    &.expire {
      color: var(--error);
    }
  }


  .delivery-method {
    font-size: 26px;
    line-height: 31px;
    text-transform: capitalize;

    .icon-express {
      width: 41px;
      height: 30px;
      background-image: url('../../assets/img/delivery/express.svg');
      background-size: 41px 30px;
    }

    .icon-standard {
      width: 41px;
      height: 30px;
      background-image: url('../../assets/img/delivery/standard.svg');
      background-size: 41px 30px;
    }
  }

  .delivery-date {
    .estimated {
      font-size: 12px;
      line-height: 15px;
    }

    .date {
      font-size: 26px;
      line-height: 31px;
    }
  }

  .details,
  .job-details {
    font-size: 14px;
    line-height: 17px;
    color: var(--faded);

    .price {
      font-size: 18px;
      line-height: 22px;
    }

    .order-price {
      font-size: 40px;
      line-height: 37px;
      color: #fff;
    }
  }

  .title {
    font-size: 31px;
    line-height: 37px;
  }

  .table {
    font-weight: 600;

    .thead {
      color: var(--ok);
    }

    .circle {
      display: inline-block;
      width: 21px;
      height: 21px;
      background: var(--faded);
      border-radius: 50%;

      &.active {
        background-color: var(--ok);
        background-image: url('../../assets/img/orders/check-icon.svg');
        background-size: 12px 10px;
        background-position: center;
        background-repeat: no-repeat;

        &.expire {
          background-color: var(--error);
          background-image: url('../../assets/img/orders/expire-icon.svg');
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .circle {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: var(--faded);
    border-radius: 50%;

    &.active {
      background-color: var(--ok);
      background-image: url('../../assets/img/orders/check-icon.svg');
      background-size: 21px 18px;
      background-position: center;
      background-repeat: no-repeat;

      &.expire {
        background-color: var(--error);
        background-image: url('../../assets/img/orders/expire-icon.svg');
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .slider {
    .circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--faded);
      background-image: none;
      margin: 0 2px;

      &.expire {
        background-color: var(--error);
        background-image: none;
      }

      &.default {
        background-color: var(--faded);
        background-image: none;
      }

      &.active {
        background-color: var(--ok);


      }
    }
  }

  .page {
    .react-pdf__Page__canvas {
      max-width: 100%;
      text-align: center;
      overflow-x: hidden;
      height: auto !important;

    }
  }
}

.order-tracking {
  .status {
    // width: 80px;
    font-size: 11px;
    line-height: 13px;
  }
}

.order-detail {
  .table {
    font-size: 16px;
    line-height: 21px;
    color: #fff;

    .thead {
      font-size: 16px;
      line-height: 21px;
      color: var(--highlight);
    }

    .job {
      cursor: pointer;

      &:hover {
        svg path {
          fill: var(--highlight);
        }

        .button-edit.expire {
          svg path {
            fill: var(--error);
          }
        }
      }
    }

    .details {
      color: var(--faded);
    }

    .button-edit {
      background-color: transparent;
      border: none;

      &.expire path {
        fill: var(--error);
      }
    }

    .details-production,
    .details-production a {
      color: var(--faded);
    }

    .expire {
      color: var(--error);
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 1279px) {
  .order-detail {
    .table {
      font-size: 14px;

      .thead {
        font-size: 14px;
      }

      .circle.active {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-top: 2px;
      }
    }
  }
}


.col-1-5 {
  width: 12.5%;
}

.text-faded {
  color: var(--faded);
}