.profile-section {
    .submenu {
        background: var(--base);
        ;
        margin-top: -7px;

        .submenu-tab {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 21px;
            color: var(--faded);
            background-color: transparent;
            border: none;
            &:hover {
                color: #FFFFFF;
                border-bottom: 2px solid var(--highlight);
                font-weight: 600;
                -webkit-transition: color 0.3s;
                transition: color 0.3s;
            }
        }
        .submenu-tab.active {
            color: #FFFFFF;
            border-bottom: 2px solid var(--highlight);
            font-weight: 600;
            -webkit-transition: color 0.3s;
            transition: color 0.3s;
        }

    }

}

.security-section,
.update-section {
    .text {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #fff;
        text-transform: capitalize;
    }

    .field-label {
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        color: var(--faded)
    }
    .form-button {
        text-transform: capitalize;
        height: 35px;
        border-color: var(--highlight);
        &.cancel {
            color: var(--faded);
            border: 1px solid var(--error);
            &:hover {
                background-color: var(--error);
                border: 1px solid var(--error);
                color: #fff;
            }
        }
        &:hover{
            background-color: var(--highlight);
        }
        &:focus {
            outline: none;
        }
    }

    .form-control,
    .form-control:focus {
        border: 0;
        border-bottom: 1px solid var(--ok);
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        height: 46px;
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;
        color: #fff;
    }

    .form-control.select,
    .form-control.select-icon {
        border: 1px solid var(--ok);
        background: transparent;
        color: #ffffff;
        border-radius: 8px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
    }

    .form-control:focus {
        outline: none;
        box-shadow: none;
    }

    .form-label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        color:var(--faded);
    }

    .selectdiv.active .select{
        border: 1px solid var(--ok);
        background: var(--ok);
    }

    .select-icon .selectdiv.active:after,
    .selectdiv.active:after {
        border-left: 1px solid #cd2929;
    }

    .selectdiv:after {
        content: url("../../assets/img/quotes/arrow-down.svg");
        font-family: "FontAwesome";
        color: #fff;
        right: 0;
        border-left: 1px solid var(--ok);
        position: absolute;
        pointer-events: none;
        padding: 0 15px;
        line-height: 21px;
        top: 40px;
    }

    .password-icon {
        background-color: transparent;
        border: none;
        position: relative;
        top: -35px;

        &:hover {
            svg path {
                fill: var(--highlight);
            }
        }
    }

.avatar {
    font-family: "Montserrat",sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;

    background: linear-gradient(0deg, var(--faded), var(--faded));
    width: 88px;
    height: 90px;
    border-radius: 50%;
}
}

@media screen and (max-width:600px) {
    .profile-section {
        .submenu {
            margin-top: 0;
        }
    }
}