/*select */
.input-invisible {
  display: none !important;
}

select::-ms-expand {
  display: none;
}
select:focus {
  outline: none;
}
.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.selectdiv {
  position: relative;
}

    .form-control.select,
    .form-control.select-icon {
        border: 1px solid var(--ok);
        background: transparent;
        color: #ffffff;
        border-radius: 8px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
    }

    .form-control.select option,
    .form-control.select-icon option {
      background: #26293a;
    }

    .form-control:focus {
        outline: none;
        box-shadow: none;
    }

    .form-label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        color:var(--faded);
    }

.selectdiv.active .select{
  border: 1px solid var(--ok);
  background: var(--ok);
}

.selectdiv:after {
  content: url("../../assets/img/quotes/arrow-down.svg");
  font-family: "FontAwesome";
  color: #fff;
  right: 0;
  border-left: 1px solid var(--ok);
  position: absolute;
  pointer-events: none;
  padding: 0 15px;
  line-height: 21px;
}

.select-icon .selectdiv.active:after,
.selectdiv.active:after {
  border-left: 1px solid #fff;
}
.select-icon .selectdiv:after{
  content: url("../../assets/img/quotes/arrow-down.svg");
  font-family: "FontAwesome";
  color: #fff;
  right: 0;
  border-left: 1px solid var(--ok);
  position: absolute;
  pointer-events: none;
  padding: 0 15px;
  line-height: 21px;
  top: 38px;
}