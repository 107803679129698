.hidden {
  display: none;
}

.section-error {
  p {
    color: white;
  }
}

.quote-list {
  .submenu {
    background: var(--base);
    margin-top: -7px;
    .submenu-tab {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      color: var(--faded);
      background-color: transparent;
      border: none;
    }
    .submenu-tab.active,
    .submenu-tab:hover {
      color: #ffffff;
      border-bottom: 2px solid var(--highlight);
      // box-shadow: inset 0px -2px 0px var(--highlight);
      // -webkit-box-shadow: inset 0px -2px 0px var(--highlight);
      font-weight: 600;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
    }
    .hide {
      display: none !important;
    }
  }

  .form-group input[type="checkbox"] {
    background: transparent;
    border: 1px solid var(--ok);
    box-sizing: border-box;
    border-radius: 8px;
    width: 38px;
    height: 38px;
    -webkit-appearance: none;
    vertical-align: middle;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .form-group.active input[type="checkbox"] {
    background: var(--ok);
    border: 1px solid var(--ok);
    box-sizing: border-box;
    border-radius: 8px;
    background-image: url("../../assets/img/quotes/checkbox.svg");
    background-size: 20px 18px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .label-checkbox {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
  }

  #customSize {
    width: 21px;
    height: 21px;
    border-radius: 4px;
    float: right;
  }

  .form-group.active #customSize {
    background-size: 13px 11px;
  }

  .label-checkbox-size {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: var(--faded);
  }

  .label-input {
    position: absolute;
  }

  .quote-delivery {
    border: 1px solid var(--ok);
    box-sizing: border-box;
    border-radius: 8px;
    // height: 290px;
    width: 261px;

    .category {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
      color: #ffffff;
    }
    .text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
    }
    .date {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #ffffff;
    }
    .final {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: var(--faded);

      .price {
        font-size: 18px;
        line-height: 20px;
      }
    }
    .final {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: var(--faded);

      .total-price {
        font-family: Montserrat;
        font-style: bold;
        font-weight: 600;
        font-size: 40px;
        line-height: 34px;
        color: #ffffff;
      }
    }
    .btn-save {
      border: 1px solid var(--highlight);
      box-sizing: border-box;
      border-radius: 8px;
      color: #fff;
      font-size: 15px;
      line-height: 18px;
      &:hover {
        color: #fff;
        background: var(--highlight);
        border: 1px solid var(--highlight);
      }
    }
  }

  .btn {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: var(--faded);
    border: 1px solid var(--faded);
    box-sizing: border-box;
    border-radius: 8px;

    &.active,
    &:hover {
      color: #fff;
      background: var(--ok);
      border: 1px solid var(--ok);
    }

    &.text-small {
      font-size: 14px;
      line-height: 17px;
    }

    &.btn-button {
      color: #fff;
      background: var(--ok);
      border: 1px solid var(--ok);
    }
  }

  .form-control.text-input {
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--ok);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #fff;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    cursor: pointer;
    display: block;
    width: 8px;
    color: #333;
    text-align: center;
    position: relative;
  }

  input[type="number"]:hover::-webkit-inner-spin-button {
    background: url("../../assets/img/quotes/input-arrows.png") no-repeat 100%;
    width: 10px;
    height: 30px;
    position: relative;
    right: 0px;
    border-radius: 0;
  }

  .form-group.active.disabled {
    opacity: 0.6;
  }

  .search-bar {
    background: #ffffff;
    border-radius: 9px;
    height: 40px;

    i.fa.fa-search {
      color: var(--ok);
    }
  }
}

.saved-quote {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;

  .table {
    min-height: 56vh;
  }

  .table {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    color: #fff;
    font-size: 17px;
    line-height: 21px;
    cursor: pointer;

    .thead {
      color: var(--highlight);
    }

    .details {
      color: var(--faded);
    }

    .express {
      position: relative;
      right: 30%;
    }

    .icon-express {
      width: 22px;
      height: 16px;
      background-image: url("../../assets/img/delivery/express.svg");
      background-size: 22px 16px;
    }
  }
}

.quote-detail,
.quote-job {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;

  .id {
    font-size: 31px;
    line-height: 37px;
  }

  .quote {
    font-size: 19px;
    line-height: 23px;
  }

  .order-details,
  .job-details {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: var(--faded);
  }

  .data {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--faded);

    .value {
      font-size: 20px;
      line-height: 29px;
      color: #fff;

      &.expire {
        color: var(--error);
      }
    }
  }

  .delivery-method {
    font-size: 26px;
    line-height: 31px;
    text-transform: capitalize;

    .icon-express {
      width: 41px;
      height: 30px;
      background-image: url("../../assets/img/delivery/express.svg");
      background-size: 41px 30px;
    }

    .icon-standard {
      width: 41px;
      height: 30px;
      background-image: url("../../assets/img/delivery/standard.svg");
      background-size: 41px 30px;
    }
  }

  .delivery-date {
    .estimated {
      font-size: 12px;
      line-height: 15px;
    }
    .date {
      font-size: 26px;
      line-height: 31px;
    }
  }

  .btn-cancel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: var(--faded);
    border: 1px solid var(--error);
    border-radius: 8px;

    &:focus {
      box-shadow: none;
    }
    &:hover {
      background-color: var(--error);
      color: #ffffff;
    }
  }

  .details,
  .job-details {
    font-size: 14px;
    line-height: 17px;
    color: var(--faded);

    .price {
      font-size: 17px;
      line-height: 21px;
    }

    .order-price {
      font-size: 40px;
      font-weight: 900;
      line-height: 60px;
      padding-left: 10px;
      color: #fff;
    }

    .total {
      font-size: 17px;
    }
  }

  .title {
    font-size: 31px;
    line-height: 37px;
  }

  .table {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #fff;

    .thead {
      font-size: 17px;
      line-height: 21px;
      color: var(--highlight);
    }

    .job {
      cursor: pointer;
    }

    .circle {
      display: inline-block;
      width: 21px;
      height: 21px;
      background: var(--faded);
      border-radius: 50%;

      &.active {
        background-color: var(--ok);
        background-image: url("../../assets/img/orders/check-icon.svg");
        background-size: 12px 10px;
        background-position: center;
        background-repeat: no-repeat;

        &.expire {
          background-color: var(--error);
          background-image: url("../../assets/img/orders/expire-icon.svg");
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .circle {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: var(--faded);
    border-radius: 50%;

    &.active {
      background-color: var(--ok);
      background-image: url("../../assets/img/orders/check-icon.svg");
      background-size: 21px 18px;
      background-position: center;
      background-repeat: no-repeat;

      &.expire {
        background-color: var(--error);
        background-image: url("../../assets/img/orders/expire-icon.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .slider {
    .circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--faded);
      background-image: none;

      &.active {
        background-color: var(--ok);

        &.expire {
          background-color: var(--error);
          background-image: none;
        }
      }
    }
  }

  .btn-save {
    border: 1px solid var(--highlight);
    box-sizing: border-box;
    border-radius: 8px;
    color: #fff;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;

    &:hover,
    &:active {
      background-color: var(--highlight);
    }

    &:focus {
      box-shadow: none;
    }
  }
}

//pagination
.pagination {
  .page-item {
    &.active {
      .page-link {
        background-color: transparent;
        color: var(--highlight);
      }
    }
  }

  .page-link {
    border: none;
    background-color: transparent;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    margin: 0 10px;
    padding: 0;

    &.side,
    &.side:hover {
      background-color: transparent;
      margin: 0 40px;
    }
    &:hover {
      color: var(--highlight);
      background-color: transparent;
    }
  }
}

//toggle
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 20px;
  height: 20px;
}

.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--faded);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 0px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .toggle {
  background-color: var(--ok);
}

input:focus + .toggle {
  box-shadow: 0 0 1px var(--faded);
}

input:checked + .toggle:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

//  Modal message style
.modal.fade.hide {
  display: none;
}

.modal.fade.show {
  display: block;
}

.modal-backdrop {
  z-index: 1;
}

.modal-content {
  background: var(--dark);
  border: 1px solid var(--highlight);
  box-sizing: border-box;
  border-radius: 7px;
  z-index: 10;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 31px;
  line-height: 37px;
  color: #fff;
}

.modal-body {
  .details {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }

  .form-control,
  .form-control:focus {
    border: 0;
    border-bottom: 1px solid var(--faded);
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    height: 35px;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    color: #fff;
  }
}

.modal-footer {
  .btn-cancel {
    color: var(--faded);
    border: 1px solid var(--error);
    box-sizing: border-box;
    border-radius: 8px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: var(--error);
      border: 1px solid var(--error);
    }
  }
  .btn-save {
    border: 1px solid var(--highlight);
    box-sizing: border-box;
    border-radius: 8px;
    color: #fff;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    &:hover{
      background-color: var(--highlight);
    }
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .quotes-section {
    .btn,
    .label-checkbox {
      font-size: 17px;
    }

    .form-control.select,
    .form-control.select-icon {
      font-size: 16px;
    }

    .quote-delivery {
      .text {
        font-size: 13px;
      }
      .date {
        font-size: 22px;
      }
    }
  }
}

@media screen and (max-width: 766px) {
  .quote-list {
    .submenu {
      .submenu-tab.active,
      .submenu-tab:hover {
        border-bottom: none;
      }
    }
  }
  .quote-detail {
    .circle {
      display: inline-block;
      width: 23px;
      height: 23px;
    }

    .block-bar {
      .circle {
        width: 30px;
        height: 30px;
        &.active {
          background-size: 10px 12px;
        }
      }
      .line {
        width: 50px;
        top: -13px;
      }
    }
    .status {
      width: 82px;
      font-size: 11px;
      line-height: 13px;
    }
    .delivery-method {
      .icon-express {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/express.svg");
        background-size: 27px 29px;
      }

      .icon-standard {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/standard.svg");
        background-size: 27px 29px;
      }
    }
  }
  .quote-job {
    .block-bar {
      .circle {
        width: 20px;
        height: 20px;
        &.active {
          background-size: 10px 12px;
        }
      }
      .line {
        width: 50px;
        top: -9px;
      }
    }
    .status {
      width: 72px;
    }

    .delivery-method {
      .icon-express {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/express.svg");
        background-size: 27px 29px;
      }

      .icon-standard {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/standard.svg");
        background-size: 27px 29px;
      }
    }
  }

  .table,
  .thead {
    font-size: 14px;
  }

  .quote-list {
    .submenu {
      .submenu-tab.active,
      .submenu-tab:hover {
        border-bottom: none;
      }
    }
  }
  .quote-detail {
    .id {
      font-size: 26px;
    }
    .circle {
      display: inline-block;
      width: 23px;
      height: 23px;
    }

    .block-bar {
      .circle {
        width: 30px;
        height: 30px;
        &.active {
          background-size: 10px 12px;
        }
      }
      .line {
        width: 50px;
        top: -13px;
      }
    }
    .status {
      width: 82px;
      font-size: 11px;
      line-height: 13px;
    }
    .delivery-method {
      .icon-express {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/express.svg");
        background-size: 27px 29px;
      }

      .icon-standard {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/standard.svg");
        background-size: 27px 29px;
      }
    }
  }
  .quote-job {
    .block-bar {
      .line {
        width: 50px;
        top: -13px;
      }
    }
    .status {
      width: 82px;
      font-size: 11px;
    }

    .delivery-method {
      .icon-express {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/express.svg");
        background-size: 27px 29px;
      }

      .icon-standard {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/standard.svg");
        background-size: 27px 29px;
      }
    }
  }

}

@media only screen and (min-width: 767px) and (max-width: 1279px) {
  .table,
  .thead {
    font-size: 14px;
  }

  .quote-detail {
    .table {
      font-size: 14px;

      .thead {
        font-size: 14px;
      }
    }

    .id {
      font-size: 26px;
    }
    .circle {
      display: inline-block;
      width: 23px;
      height: 23px;
    }

    .block-bar {
      .circle {
        width: 30px;
        height: 30px;
        &.active {
          background-size: 10px 12px;
        }
      }
      .line {
        width: 50px;
        top: -13px;
      }
    }
    .status {
      width: 82px;
      font-size: 11px;
      line-height: 13px;
    }
    .delivery-method {
      .icon-express {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/express.svg");
        background-size: 27px 29px;
      }

      .icon-standard {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/standard.svg");
        background-size: 27px 29px;
      }
    }
  }
  .quote-job {
    .block-bar {
      .line {
        width: 50px;
        top: -13px;
      }
    }
    .status {
      width: 80px;
      font-size: 11px;
    }

    .delivery-method {
      .icon-express {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/express.svg");
        background-size: 27px 29px;
      }

      .icon-standard {
        width: 27px;
        height: 29px;
        background-image: url("../../assets/img/delivery/standard.svg");
        background-size: 27px 29px;
      }
    }
  }
}
