/* styles */
.header {
    background-color: var(--dark);

    .text-logo {
        font-family: 'SF New Republic';
        font-style: normal;
        font-weight: normal;
        font-size: 45px;
        line-height: 39px;
        letter-spacing: -0.05em;
        color: #FFFFFF;
        position: relative;
        right: 10px;
        top: 30px;

        span {
            font-family: Montserrat;
            font-weight: normal;
       }
    }

    .nav-link {
        position:relative;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        color: var(--faded);
    }

    .nav-item.active,
    .nav-item:hover {
        .nav-link {
            display: initial;
            color: #FFFFFF;
            border-bottom: 2px solid var(--highlight);
        }
    }

    .badge {
        position: absolute;
        top: 16px;
        // right: -14px;
        // height: 16px;
        width: 20px;
        font-size: 10px;
        line-height: 14px;
    }

    .navbar-toggler-icon {
        color: var(--faded);

        &:hover,
        &:active {
            color: var(--highlight);
        }
    }

    div#headerMenu {
        .show {
           display: block;
        }

        .hide {
           display: none;
        }
    }

    .profile-picture {
        width: 36px;
        height: 36px;
        border-radius: 50%;
    }

    .avatar {
        color: #fff;
        background: linear-gradient(0deg, var(--faded), var(--faded));
        width: 36px;
        height: 36px;
        border-radius: 50%;

        &:hover {
            transition: color 0.3s;
            opacity: 0.9;
        }
    }

    .profile-pic {
        .nav-link {
            &:hover {
                box-shadow: inset 0px -2px 0px var(--highlight);
                -webkit-box-shadow: inset 0px -2px 0px var(--highlight);
            }
        }
    }

    .mobile {
        #headerMenu {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            top: 70px;
            z-index: 2;
            background-color: #25293A;
        }

        .nav-item {
            border-bottom: 2px solid var(--faded);

            &.active,
            &:hover {
                border-bottom: 2px solid var(--highlight);

                .nav-link {
                    border-bottom: none;
                }
            }
        }
    }
}

.sticky-top {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width:766px) {
    .header {
        .badge {
            top: 0px;
            // right: -8px;
        }

        .nav-item.active {
            .badge {
                // right: -20px;
            }
        }
    }
}

@media only screen and (min-width:767px) and (max-width: 1279px) {
    .header {
        .badge {
            top: 12px;
            // right: -12px;
        }

        .nav-item.active {
            .badge {
                // right: -12px;
            }
        }
    }
}