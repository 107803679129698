.block-bar {
    display: inline-block;

    .circle {
        display:inline-block;
        width: 32px;
        height: 32px;
        background: var(--faded);
        border-radius: 50%;

        &.active {
            background-color: var(--ok);
            background-image: url('../../assets/img/orders/check-icon.svg');
            background-size: 21px 18px;
            background-position: center;
            background-repeat: no-repeat;

            &.expire {
                background-color: var(--error);
                background-image: url('../../assets/img/orders/expire-icon.svg');
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
    .line {
        display:inline-block;
        width: 70px;
        height: 2px;
        background-color: var(--faded);
        position: relative;
        top: -15px;

        &.active {
            background-color: var(--ok);
        }
    }
}

.status {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: var(--faded);
    width: 100px;

    &.active {
        color: #fff;
    }

    &.expire {
        color: var(--error);
    }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
    .bar-job {
        .line {
            width: 100px;
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
    .bar-job {
        .line {
            width: 80px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .block-bar {
        .line {
            width: 65px;
        }
    }

    .status {
        width: 100px;
    }
}