/* FONTS */
@import url('https://fonts.googleapis.com/css?family=Montserrat:500,600&display=swap');

/* @font-face {
    font-family: 'SF New Republic';
    src: url('./assets/fonts/sf-new-republic/SFNewRepublic.woff2') format('woff2'),
        url('./assets/fonts/sf-new-republic/SFNewRepublic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./assets/fonts/proxima-nova/ProximaNova-Regular.woff2') format('woff2'),
        url('./assets/fonts/proxima-nova/ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */

/* GENERAL */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin: 0 !important;
}
a,
a:hover,
a:focus {
  color: #fff;
  text-decoration: none !important;
}

.text-uppercase {
  text-transform: uppercase;
}

input:focus,
button:focus {
  outline: none;
}
