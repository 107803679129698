.production-chart {
    .progress-chart {
        width: 170px;
        height: 148px;
        cursor: pointer;
    }

    .circle {
        display:inline-block;
        width: 21px;
        height: 21px;
        background: var(--faded);
        border-radius: 50%;

        &.active {
            background-color: var(--ok);
            background-image: url('../../../assets/img/orders/check-icon.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
        &.expire {
            background-color: var(--error);
            background-image: url('../../../assets/img/orders/expire-icon.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .text-chart {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    }

    .CircularProgressbar-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        line-height: 50px;
        text-align: center;
        color: #FFFFFF;
    }
}