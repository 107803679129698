.section-error {
    p {
        color: white
    }
}

.table {
    font-size: 16px;
    line-height: 21px;
    color: #fff;

    .thead {
        font-size: 16px;
        line-height: 21px;
        color: var(--highlight);
    }

    .job {
        cursor: pointer;

        &:hover {
            svg path {
                fill: var(--highlight);
            }

            .button-edit.expire {
                svg path {
                    fill: var(--error);
                }
            }
        }
    }

    .details {
        color: var(--faded);
    }

    .button-edit {
        background-color: transparent;
        border: none;

        &.expire path {
            fill: var(--error);
        }
    }

    .details-production,
    .details-production a {
        color: var(--faded);
    }

    .expire {
        color: var(--error);
    }
}

.orders-section {
    .submenu {
        background: var(--base);
        margin-top: -7px;

        .submenu-tab {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 21px;
            color: var(--faded);
            background-color: transparent;
            border: none;
        }

        .submenu-tab.active,
        .submenu-tab:hover {
            color: #FFFFFF;
            border-bottom: 2px solid var(--highlight);
            // box-shadow: inset 0px -2px 0px var(--highlight);
            // -webkit-box-shadow: inset 0px -2px 0px var(--highlight);
            font-weight: 600;
            -webkit-transition: color 0.3s;
            transition: color 0.3s;
        }

        .btn-back {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
            color: var(--faded);
            background-color: transparent;
            border: none;

            &:hover {
                color: var(--ok);

                svg path {
                    fill: var(--ok);
                }
            }
        }

        .badge {
            position: relative;
            top: -10px;
            left: 3px;
            height: 16px;
            width: 16px;
            font-size: 10px;
            line-height: 12px;
        }

    }

    .search-bar {
        background: #FFFFFF;
        border-radius: 9px;
        height: 40px;

        i.fa.fa-search {
            color: var(--ok);
        }
    }

    .order-detail,
    .order-job,
    .order-artwork,
    .order-tracking {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        color: #FFFFFF;

        .id {
            font-size: 31px;
            line-height: 37px;
        }

        .quote {
            font-size: 19px;
            line-height: 23px;
        }

        .order-details,
        .job-details {
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            color: var(--faded);
        }

        .data {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: var(--faded);
        }

        .value {
            font-size: 22px;
            line-height: 29px;
            color: #fff;

            &.expire {
                color: var(--error);
            }
        }


        .delivery-method {
            font-size: 26px;
            line-height: 31px;
            text-transform: capitalize;

            .icon-express {
                width: 41px;
                height: 30px;
                background-image: url('../../assets/img/delivery/express.svg');
                background-size: 41px 30px;
            }

            .icon-standard {
                width: 41px;
                height: 30px;
                background-image: url('../../assets/img/delivery/standard.svg');
                background-size: 41px 30px;
            }
        }

        .delivery-date {
            .estimated {
                font-size: 12px;
                line-height: 15px;
            }

            .date {
                font-size: 26px;
                line-height: 31px;
            }
        }

        .details,
        .job-details {
            font-size: 14px;
            line-height: 17px;
            color: var(--faded);

            .price {
                font-size: 18px;
                line-height: 22px;
            }

            .order-price {
                font-size: 40px;
                line-height: 37px;
                color: #fff;
            }
        }

        .title {
            font-size: 31px;
            line-height: 37px;
        }

        .table {
            font-weight: 600;

            .thead {
                color: var(--ok);
            }
            .circle {
                display: inline-block;
                width: 21px;
                height: 21px;
                background: var(--faded);
                border-radius: 50%;

                &.active {
                    background-color: var(--ok);
                    background-image: url('../../assets/img/orders/check-icon.svg');
                    background-size: 12px 10px;
                    background-position: center;
                    background-repeat: no-repeat;

                    &.expire {
                        background-color: var(--error);
                        background-image: url('../../assets/img/orders/expire-icon.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }
        }

        .circle {
            display: inline-block;
            width: 32px;
            height: 32px;
            background: var(--faded);
            border-radius: 50%;

            &.active {
                background-color: var(--ok);
                background-image: url('../../assets/img/orders/check-icon.svg');
                background-size: 21px 18px;
                background-position: center;
                background-repeat: no-repeat;

                &.expire {
                    background-color: var(--error);
                    background-image: url('../../assets/img/orders/expire-icon.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }

        .slider {
            .circle {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--faded);
                background-image: none;
                margin: 0 2px;

                &.expire {
                    background-color: var(--error);
                    background-image: none;
                }
                &.default {
                    background-color: var(--faded);
                    background-image: none;
                }
                &.active {
                    background-color: var(--ok);


                }
            }
        }

        .page {
            .react-pdf__Page__canvas {
                max-width: 100%;
                text-align: center;
                overflow-x: hidden;
                height: auto !important;

            }
        }
    }
}

.order-list {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;

    table {
        font-size: 17px;
        line-height: 21px;
        color: #FFFFFF;
    }

    thead {
      color: var(--highlight);
    }

    .table {
        min-height: 56vh;
    }


    .block-bar {
        display: inline-block;

        .circle {
            display: inline-block;
            width: 21px;
            height: 21px;
            background: var(--faded);
            border-radius: 50%;

            &.active {
                background-color: var(--ok);
                background-image: url('../../assets/img/orders/check-icon.svg');
                background-size: 12px 10px;
                background-position: center;
                background-repeat: no-repeat;

                &.expire {
                    background-color: var(--error);
                    background-image: url('../../assets/img/orders/expire-icon.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }

        .line {
            display: inline-block;
            width: 8px;
            height: 2px;
            background-color: var(--faded);
            position: relative;
            top: -9px;

            &.active {
                background-color: var(--ok);
            }
        }
    }

    .icon-express {
        width: 22px;
        height: 16px;
        background-image: url('../../assets/img/delivery/express.svg');
        background-size: 22px 16px;
    }

}

.order-artwork {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;

    .time {
        font-size: 22px;
        line-height: 29px;

        .expire {
            color: var(--error);
        }
    }

    .form-control {
        background: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid var(--ok);
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #fff;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .btn-save {
        border: 1px solid var(--highlight);
        box-sizing: border-box;
        border-radius: 8px;
        color: #fff;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;

        &:hover,
        &:active {
            background-color: var(--highlight);
        }

        &:focus {
            box-shadow: none;
        }
    }

    .btn-reject {
        border: 1px solid var(--error);
        box-sizing: border-box;
        border-radius: 8px;
        color: #fff;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;

        &:hover,
        &:active {
            background-color: var(--error);
        }

        &:focus {
            box-shadow: none;
        }
    }

    .expired {
        .expire {
            color: var(--error);
            font-size: 12px;
            line-height: 15px;
        }

        .date-icon {
            background-color: transparent;
            border: none;
            position: relative;
            top: -35px;
        }

        .date-picker {
            .react-datepicker-wrapper {
                width: 100%;

                .date-input {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 23px;
                    color: #fff;
                    padding: 10px;
                    border-bottom: 2px solid var(--highlight);
                }
            }

            .react-datepicker {
                background-color: #272856;
                border: 1px solid var(--highlight);
                box-sizing: border-box;
                border-radius: 8px;
                width: 93%;
            }

            .react-datepicker-popper,
            .react-datepicker__month-container {
                width: 100%;
            }

            .react-datepicker__header {
                background-color: transparent;
                width: 100%;
                padding: 8px 15px;
                border-bottom: 0;

                .react-datepicker__current-month {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 23px;
                    color: #FFFFFF;
                }

                .react-datepicker__day-names {
                    display: flex;
                    justify-content: space-around;
                    margin: 15px 0 0;
                }

                .react-datepicker__day-name {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 23px;
                    color: var(--highlight);
                }

            }

            .react-datepicker__navigation--next {
                background: url('../../assets/img/artworks/arrow-forward.svg') no-repeat;
                width: 20px;
                height: 20px;
                border: none;

            }

            .react-datepicker__navigation--previous {
                background: url('../../assets/img/artworks/arrow-backward.svg') no-repeat;
                width: 20px;
                height: 20px;
                border: none;

            }

            .react-datepicker__week {
                width: 100%;
                margin: 0;
                display: flex;
                justify-content: space-around;

                .react-datepicker__day--today {
                    border-radius: 50%;
                    background-color: var(--highlight);
                    color: #fff;
                    font-weight: 600;
                }

                .react-datepicker__day {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 21px;
                    color: #fff;
                    width: 34px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        border-radius: 50%;
                        background-color: var(--highlight);
                        color: #fff;
                        font-weight: 600;
                    }
                }

                .react-datepicker__day--disabled {
                    color: var(--faded);
                    &:hover {
                        border-radius: 50%;
                        background-color: #272856;
                        color: var(--faded);
                        font-weight: 600;

                    }
                }

                .react-datepicker__day--selected {
                    border-radius: 50%;
                    background-color: var(--highlight);

                    &.react-datepicker__day--outside-month {
                        color: #fff;
                    }
                }

                .react-datepicker__day--outside-month {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 21px;
                    color: var(--faded);
                }
            }

            .react-datepicker__triangle {
                display: none;
            }
        }

        .time {
            border-bottom: 2px solid var(--highlight);
        }

        .time-icon {
            background-color: transparent;
            border: none;
            position: relative;
            top: -50px;
        }
    }

    .artwork {
        .status {
            position: relative;
            right: 13%;
            font-size: 22px;
            line-height: 29px;

            .value {
                color: #fff;

                &.expire {
                    color: var(--error);
                }
            }
        }
    }

    .disabled {
        .form-control {
            border-bottom: 1px solid var(--faded);
        }

        .btn-save,
        .btn-reject {
            border: 1px solid var(--faded);
            color: var(--faded);

            &:active,
            &:hover {
                background-color: transparent;
            }
        }
    }
}

@media screen and (max-width: 766px) {
    .orders-section {
    .order-list {
        .block-bar {
            .circle {
                width: 16px;
                height: 16px;

                &.active {
                    background-size: 10px 8px;
                }
            }

            .line {
                top: -7px;
            }
        }
    }

    .order-detail,
    .order-artwork .time,
    .order-artwork .artwork {
        .delivery-method {
            .icon-express {
              width: 27px;
              height: 29px;
              background-image: url('../../assets/img/delivery/express.svg');
              background-size: 27px 29px;
            }

            .icon-standard {
              width: 27px;
              height: 29px;
              background-image: url('../../assets/img/delivery/standard.svg');
              background-size: 27px 29px;
            }
        }

        .circle.active {
            display: inline-block;
            width: 23px;
            height: 23px;
            background-size: 15px 15px;
        }

        .table {
            .circle.active {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-top: 2px;
            }
        }

        .block-bar {
            .circle {
              width: 30px;
              height: 30px;
            }
            .line {
              width: 45px;
              top: -13px;
            }
        }
          .status {
            width: 75px;
            font-size: 10px;
            line-height: 13px;
        }
        .mapboxgl-map {
            width: 250px;
            height: 185px;
        }
    }

    .order-job,
    .order-tracking {
        .id {
            .circle.active {
                display: inline-block;
                width: 23px;
                height: 23px;
                margin-top: 3px;
            }
        }

        .cover-icon {
            width: 25px;
            height: 38px;
            margin-top: 20px;
        }

        .delivery-method {
            .icon-express {
              width: 27px;
              height: 29px;
              background-image: url('../../assets/img/delivery/express.svg');
              background-size: 27px 29px;
            }

            .icon-standard {
              width: 27px;
              height: 29px;
              background-image: url('../../assets/img/delivery/standard.svg');
              background-size: 27px 29px;
            }
        }

        .block-bar {
            .circle {
              width: 30px;
              height: 30px;
            }
            .line {
              width: 37px;
              top: -13px;
            }
        }
          .status {
            width: 66px;
            font-size: 10px;
            line-height: 13px;
        }
    }

    .order-tracking {
        .block-bar {
            .circle {
              width: 30px;
              height: 30px;
            }
            .line {
              width: 30px;
              top: -13px;
            }
        }
          .status {
            width: 63px;
            font-size: 10px;
            line-height: 13px;

            .details {
                font-size: 10px;
            }
        }
        .mapboxgl-map {
            width: 250px;
            height: 185px;
        }
    }

    .order-detail {
        .table {
            font-size: 14px;
        }
        .mapboxgl-map {
            width: 250px !important;
            height: 185px !important;
        }
    }

    .order-artwork {
        .page {
            .react-pdf__Page__canvas {
                max-width: 100%;
                text-align: center;
                overflow-x: hidden;
                width: 400px !important;
                height: auto !important;
            }
        }
    }
    .page-link {
        &.side,
        &.side:hover {
          background-color: transparent;
          margin: 0 20px;
        }
      }
}
}
@media only screen and (min-width: 767px) and (max-width: 1279px) {

    .orders-section {
    .order-list {
        .block-bar {
            .circle {
                width: 16px;
                height: 16px;

                &.active {
                    background-size: 10px 8px;
                }
            }

            .line {
                top: -7px;
            }
        }
    }

    .order-detail,
    .order-artwork .time,
    .order-artwork .artwork {

        .delivery-method {
            .icon-express {
              width: 27px;
              height: 29px;
              background-image: url('../../assets/img/delivery/express.svg');
              background-size: 27px 29px;
            }

            .icon-standard {
              width: 27px;
              height: 29px;
              background-image: url('../../assets/img/delivery/standard.svg');
              background-size: 27px 29px;
            }
        }

        .circle.active {
            display: inline-block;
            width: 23px;
            height: 23px;
            background-size: 15px 15px;
        }

        .table {
            font-size: 14px;

            .thead {
                font-size: 14px;
            }

            .circle.active {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-top: 2px;
            }
        }

        .block-bar {
            .circle {
              width: 30px;
              height: 30px;
            }
            .line {
              width: 45px;
              top: -13px;
            }
        }
          .status {
            width: 75px;
            font-size: 10px;
            line-height: 13px;
        }
    }

    .order-job,
    .order-tracking {
        .table, .thead {
            font-size: 14px;
        }

        .id {
            .circle.active {
                display: inline-block;
                width: 23px;
                height: 23px;
                margin-top: 3px;
            }
        }

        .cover-icon {
            width: 25px;
            height: 38px;
            margin-top: 20px;
        }

        .delivery-method {
            .icon-express {
              width: 27px;
              height: 29px;
              background-image: url('../../assets/img/delivery/express.svg');
              background-size: 27px 29px;
            }

            .icon-standard {
              width: 27px;
              height: 29px;
              background-image: url('../../assets/img/delivery/standard.svg');
              background-size: 27px 29px;
            }
        }

        .block-bar {
            .circle {
              width: 30px;
              height: 30px;
            }
            .line {
              width: 37px;
              top: -13px;
            }
        }
          .status {
            width: 69px;
            font-size: 11px;
            line-height: 13px;
        }

    }

    .order-tracking {
        .block-bar {
            .circle {
              width: 30px;
              height: 30px;
            }
            .line {
              width: 50px;
              top: -13px;
            }
        }
        .status {
            width: 80px;
            font-size: 11px;
            line-height: 13px;
        }
    }

    .mapboxgl-map {
        width: 230px !important;
        height: 165px !important;
    }
}
}