@import url(https://fonts.googleapis.com/css?family=Montserrat:500,600&display=swap);

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* OVERIDE */

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  top: 18px!important;
}

.dataTable>tbody>tr>td {
  padding-top: 0!important;
  padding-bottom: 0!important;
}
.spinner-animated {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 2000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 2000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.loaderApp {
  position: absolute;
  width: 100%;
  height: 100%; }
  .loaderApp .spinContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%); }

/* FONTS */

/* @font-face {
    font-family: 'SF New Republic';
    src: url('./assets/fonts/sf-new-republic/SFNewRepublic.woff2') format('woff2'),
        url('./assets/fonts/sf-new-republic/SFNewRepublic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./assets/fonts/proxima-nova/ProximaNova-Regular.woff2') format('woff2'),
        url('./assets/fonts/proxima-nova/ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */

/* GENERAL */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin: 0 !important;
}
a,
a:hover,
a:focus {
  color: #fff;
  text-decoration: none !important;
}

.text-uppercase {
  text-transform: uppercase;
}

input:focus,
button:focus {
  outline: none;
}

/* styles */
body {
  background: linear-gradient(180deg, var(--dark) 0%, var(--dark) 59.9%, var(--base) 100%) 100%;
  background-attachment: fixed;
  font-family: Montserrat; }

.error {
  color: var(--error);
  font-size: 14px; }

.blue-notification {
  color: #08b1fb; }

.logo img {
  width: 220px;
  margin-left: -10px; }

.text-logo {
  font-family: 'SF New Republic';
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 57px;
  letter-spacing: -0.05em;
  color: #FFFFFF;
  position: relative;
  right: 50px;
  top: 40px; }
  .text-logo span {
    font-family: Montserrat;
    font-weight: normal; }

.hello-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 78px;
  color: #ffffff; }

.field-label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fff; }

input,
input:focus {
  outline: none;
  border: 0;
  background-color: transparent;
  color: var(--faded); }

.form-button {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid var(--highlight);
  border-radius: 6px;
  height: 45px;
  width: 100%; }

.form-button.active,
.form-button.active:hover,
.form-button:hover {
  background: var(--highlight);
  border-radius: 7px;
  color: #fff; }

.form-forgot {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: var(--faded); }

.rc-anchor-light {
  background: transparent !important;
  border: 1px solid var(--faded) !important; }

.login-section .form-control,
.register-section .form-control,
.forgot-section .form-control {
  border: 0;
  border-bottom: 1px solid var(--highlight);
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  height: 46px;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  color: #fff; }
  .login-section .form-control:focus,
  .register-section .form-control:focus,
  .forgot-section .form-control:focus {
    font-weight: 600; }

.error-check-user p {
  color: white; }

.production-chart .progress-chart {
  width: 170px;
  height: 148px;
  cursor: pointer; }

.production-chart .circle {
  display: inline-block;
  width: 21px;
  height: 21px;
  background: var(--faded);
  border-radius: 50%; }
  .production-chart .circle.active {
    background-color: var(--ok);
    background-image: url(/static/media/check-icon.10e4d33b.svg);
    background-position: center;
    background-repeat: no-repeat; }
  .production-chart .circle.expire {
    background-color: var(--error);
    background-image: url(/static/media/expire-icon.7a296537.svg);
    background-position: center;
    background-repeat: no-repeat; }

.production-chart .text-chart {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF; }

.production-chart .CircularProgressbar-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 50px;
  text-align: center;
  color: #FFFFFF; }

.quotes-chart .total-number {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 65px;
  line-height: 79px;
  color: #FFFFFF;
  cursor: pointer; }

.quotes-chart .text-chart {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF; }

.quotes-chart .chart-all {
  height: 120px;
  background-color: var(--faded);
  transform: rotateX(180deg);
  cursor: pointer; }
  .quotes-chart .chart-all .full-filled {
    background-color: var(--highlight); }

.jobs-chart .chart-bar {
  display: flex;
  transform: rotateX(180deg);
  height: 122px; }
  .jobs-chart .chart-bar .bar {
    position: relative;
    width: 28px;
    margin-left: 5px;
    background: var(--faded);
    display: inline-block;
    transition: all 0.3s ease-in-out; }
    .jobs-chart .chart-bar .bar:hover {
      cursor: pointer; }
    .jobs-chart .chart-bar .bar .counter-block {
      opacity: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      transform: rotateX(180deg);
      position: absolute;
      color: white;
      top: 100%;
      transition: all 0.3s ease-in-out; }
    .jobs-chart .chart-bar .bar:hover {
      background: var(--highlight);
      width: 65px;
      text-align: center; }
      .jobs-chart .chart-bar .bar:hover .counter-block {
        opacity: 1; }
    .jobs-chart .chart-bar .bar.activeMonth {
      background: var(--highlight);
      width: 65px; }
      .jobs-chart .chart-bar .bar.activeMonth .counter-block {
        opacity: 1; }
    .jobs-chart .chart-bar .bar.currentMonth {
      width: 65px;
      background: var(--highlight); }
      .jobs-chart .chart-bar .bar.currentMonth p {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        transform: rotateX(180deg);
        position: absolute;
        color: white;
        top: 100%; }

.jobs-chart .chart-month {
  font-family: Montserrat; }
  .jobs-chart .chart-month div {
    width: 28px;
    margin-left: 5px;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--faded);
    transition: all 0.3s ease-in-out;
    text-align: center; }
    .jobs-chart .chart-month div:hover {
      color: #FFFFFF;
      text-align: center; }
    .jobs-chart .chart-month div.activeMonthText {
      color: #FFFFFF;
      width: 65px;
      cursor: pointer; }
    .jobs-chart .chart-month div.active {
      color: #FFFFFF;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      width: 65px;
      text-align: center; }

.jobs-chart .total-number {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 64.6445px;
  line-height: 79px;
  color: #FFFFFF;
  cursor: pointer; }

/* styles */
.header {
  background-color: var(--dark); }
  .header .text-logo {
    font-family: 'SF New Republic';
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 39px;
    letter-spacing: -0.05em;
    color: #FFFFFF;
    position: relative;
    right: 10px;
    top: 30px; }
    .header .text-logo span {
      font-family: Montserrat;
      font-weight: normal; }
  .header .nav-link {
    position: relative;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: var(--faded); }
  .header .nav-item.active .nav-link,
  .header .nav-item:hover .nav-link {
    display: initial;
    color: #FFFFFF;
    border-bottom: 2px solid var(--highlight); }
  .header .badge {
    position: absolute;
    top: 16px;
    width: 20px;
    font-size: 10px;
    line-height: 14px; }
  .header .navbar-toggler-icon {
    color: var(--faded); }
    .header .navbar-toggler-icon:hover, .header .navbar-toggler-icon:active {
      color: var(--highlight); }
  .header div#headerMenu .show {
    display: block; }
  .header div#headerMenu .hide {
    display: none; }
  .header .profile-picture {
    width: 36px;
    height: 36px;
    border-radius: 50%; }
  .header .avatar {
    color: #fff;
    background: linear-gradient(0deg, var(--faded), var(--faded));
    width: 36px;
    height: 36px;
    border-radius: 50%; }
    .header .avatar:hover {
      transition: color 0.3s;
      opacity: 0.9; }
  .header .profile-pic .nav-link:hover {
    box-shadow: inset 0px -2px 0px var(--highlight);
    -webkit-box-shadow: inset 0px -2px 0px var(--highlight); }
  .header .mobile #headerMenu {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 70px;
    z-index: 2;
    background-color: #25293A; }
  .header .mobile .nav-item {
    border-bottom: 2px solid var(--faded); }
    .header .mobile .nav-item.active, .header .mobile .nav-item:hover {
      border-bottom: 2px solid var(--highlight); }
      .header .mobile .nav-item.active .nav-link, .header .mobile .nav-item:hover .nav-link {
        border-bottom: none; }

.sticky-top {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25); }

@media screen and (max-width: 766px) {
  .header .badge {
    top: 0px; } }

@media only screen and (min-width: 767px) and (max-width: 1279px) {
  .header .badge {
    top: 12px; } }

:root {
  --highlight: #8FD243;
  --faded: #578399;
  --dark: #09131D;
  --base: #152E46;
  --error: #FF004D;
  --ok: #12ADBE; }

.button-edit,
.button-remove {
  background-color: transparent;
  border: none; }
  .button-edit:hover path,
  .button-remove:hover path {
    fill: var(--highlight); }

.home-section .widget-list {
  min-height: 76vh; }

.home-section .widgets-slider {
  display: none; }
  .home-section .widgets-slider.active {
    display: block; }

.home-section .text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
  color: #FFFFFF; }

.home-section .text-description {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF; }

.home-section .text-small {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  cursor: pointer; }
  .home-section .text-small:hover svg path {
    fill: var(--highlight); }

.home-section .order-count {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 65px;
  line-height: 79px;
  color: var(--highlight); }

.home-section .order-number {
  color: var(--highlight);
  font-weight: bold; }

.home-section .widget {
  width: 294px; }

.home-section .quote-count,
.home-section .job-count {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 65px;
  line-height: 79px;
  color: var(--faded); }

.home-section .expire {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  color: var(--error); }

.error-section p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  line-height: 106px;
  color: #fff; }
  .error-section p.text-oops {
    font-size: 87px; }
  .error-section p.text-page {
    font-size: 18px;
    line-height: 24px; }
  .error-section p.text-error {
    font-size: 106px; }

.error-section .form-button {
  height: 60px;
  font-size: 15px;
  line-height: 19px; }

.print-section {
  color: #fff; }
  .print-section .card {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 14px;
    background-color: transparent;
    border: none; }
  .print-section .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF; }

@media screen and (max-width: 766px) {
  .home-section .pagination {
    display: none; }
  .home-section .widgets-slider {
    display: block; } }

/*select */
.input-invisible {
  display: none !important;
}

select::-ms-expand {
  display: none;
}
select:focus {
  outline: none;
}
.selectdiv select {
  -webkit-appearance: none;
  appearance: none;
}

.selectdiv {
  position: relative;
}

    .form-control.select,
    .form-control.select-icon {
        border: 1px solid var(--ok);
        background: transparent;
        color: #ffffff;
        border-radius: 8px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
    }

    .form-control.select option,
    .form-control.select-icon option {
      background: #26293a;
    }

    .form-control:focus {
        outline: none;
        box-shadow: none;
    }

    .form-label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        color:var(--faded);
    }

.selectdiv.active .select{
  border: 1px solid var(--ok);
  background: var(--ok);
}

.selectdiv:after {
  content: url(/static/media/arrow-down.0c9f025a.svg);
  font-family: "FontAwesome";
  color: #fff;
  right: 0;
  border-left: 1px solid var(--ok);
  position: absolute;
  pointer-events: none;
  padding: 0 15px;
  line-height: 21px;
}

.select-icon .selectdiv.active:after,
.selectdiv.active:after {
  border-left: 1px solid #fff;
}
.select-icon .selectdiv:after{
  content: url(/static/media/arrow-down.0c9f025a.svg);
  font-family: "FontAwesome";
  color: #fff;
  right: 0;
  border-left: 1px solid var(--ok);
  position: absolute;
  pointer-events: none;
  padding: 0 15px;
  line-height: 21px;
  top: 38px;
}
.profile-section .submenu {
  background: var(--base);
  margin-top: -7px; }
  .profile-section .submenu .submenu-tab {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: var(--faded);
    background-color: transparent;
    border: none; }
    .profile-section .submenu .submenu-tab:hover {
      color: #FFFFFF;
      border-bottom: 2px solid var(--highlight);
      font-weight: 600;
      transition: color 0.3s; }
  .profile-section .submenu .submenu-tab.active {
    color: #FFFFFF;
    border-bottom: 2px solid var(--highlight);
    font-weight: 600;
    transition: color 0.3s; }

.security-section .text,
.update-section .text {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #fff;
  text-transform: capitalize; }

.security-section .field-label,
.update-section .field-label {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: var(--faded); }

.security-section .form-button,
.update-section .form-button {
  text-transform: capitalize;
  height: 35px;
  border-color: var(--highlight); }
  .security-section .form-button.cancel,
  .update-section .form-button.cancel {
    color: var(--faded);
    border: 1px solid var(--error); }
    .security-section .form-button.cancel:hover,
    .update-section .form-button.cancel:hover {
      background-color: var(--error);
      border: 1px solid var(--error);
      color: #fff; }
  .security-section .form-button:hover,
  .update-section .form-button:hover {
    background-color: var(--highlight); }
  .security-section .form-button:focus,
  .update-section .form-button:focus {
    outline: none; }

.security-section .form-control,
.security-section .form-control:focus,
.update-section .form-control,
.update-section .form-control:focus {
  border: 0;
  border-bottom: 1px solid var(--ok);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  height: 46px;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  color: #fff; }

.security-section .form-control.select,
.security-section .form-control.select-icon,
.update-section .form-control.select,
.update-section .form-control.select-icon {
  border: 1px solid var(--ok);
  background: transparent;
  color: #ffffff;
  border-radius: 8px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px; }

.security-section .form-control:focus,
.update-section .form-control:focus {
  outline: none;
  box-shadow: none; }

.security-section .form-label,
.update-section .form-label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: var(--faded); }

.security-section .selectdiv.active .select,
.update-section .selectdiv.active .select {
  border: 1px solid var(--ok);
  background: var(--ok); }

.security-section .select-icon .selectdiv.active:after,
.security-section .selectdiv.active:after,
.update-section .select-icon .selectdiv.active:after,
.update-section .selectdiv.active:after {
  border-left: 1px solid #cd2929; }

.security-section .selectdiv:after,
.update-section .selectdiv:after {
  content: url(/static/media/arrow-down.0c9f025a.svg);
  font-family: "FontAwesome";
  color: #fff;
  right: 0;
  border-left: 1px solid var(--ok);
  position: absolute;
  pointer-events: none;
  padding: 0 15px;
  line-height: 21px;
  top: 40px; }

.security-section .password-icon,
.update-section .password-icon {
  background-color: transparent;
  border: none;
  position: relative;
  top: -35px; }
  .security-section .password-icon:hover svg path,
  .update-section .password-icon:hover svg path {
    fill: var(--highlight); }

.security-section .avatar,
.update-section .avatar {
  font-family: "Montserrat",sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #FFFFFF;
  background: linear-gradient(0deg, var(--faded), var(--faded));
  width: 88px;
  height: 90px;
  border-radius: 50%; }

@media screen and (max-width: 600px) {
  .profile-section .submenu {
    margin-top: 0; } }

.hidden {
  display: none; }

.section-error p {
  color: white; }

.quote-list .submenu {
  background: var(--base);
  margin-top: -7px; }
  .quote-list .submenu .submenu-tab {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: var(--faded);
    background-color: transparent;
    border: none; }
  .quote-list .submenu .submenu-tab.active,
  .quote-list .submenu .submenu-tab:hover {
    color: #ffffff;
    border-bottom: 2px solid var(--highlight);
    font-weight: 600;
    transition: color 0.3s; }
  .quote-list .submenu .hide {
    display: none !important; }

.quote-list .form-group input[type="checkbox"] {
  background: transparent;
  border: 1px solid var(--ok);
  box-sizing: border-box;
  border-radius: 8px;
  width: 38px;
  height: 38px;
  -webkit-appearance: none;
  vertical-align: middle; }
  .quote-list .form-group input[type="checkbox"]:focus {
    outline: none;
    box-shadow: none; }

.quote-list .form-group.active input[type="checkbox"] {
  background: var(--ok);
  border: 1px solid var(--ok);
  box-sizing: border-box;
  border-radius: 8px;
  background-image: url(/static/media/checkbox.0fa3c359.svg);
  background-size: 20px 18px;
  background-repeat: no-repeat;
  background-position: center; }

.quote-list .label-checkbox {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff; }

.quote-list #customSize {
  width: 21px;
  height: 21px;
  border-radius: 4px;
  float: right; }

.quote-list .form-group.active #customSize {
  background-size: 13px 11px; }

.quote-list .label-checkbox-size {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: var(--faded); }

.quote-list .label-input {
  position: absolute; }

.quote-list .quote-delivery {
  border: 1px solid var(--ok);
  box-sizing: border-box;
  border-radius: 8px;
  width: 261px; }
  .quote-list .quote-delivery .category {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    color: #ffffff; }
  .quote-list .quote-delivery .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff; }
  .quote-list .quote-delivery .date {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #ffffff; }
  .quote-list .quote-delivery .final {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--faded); }
    .quote-list .quote-delivery .final .price {
      font-size: 18px;
      line-height: 20px; }
  .quote-list .quote-delivery .final {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--faded); }
    .quote-list .quote-delivery .final .total-price {
      font-family: Montserrat;
      font-style: bold;
      font-weight: 600;
      font-size: 40px;
      line-height: 34px;
      color: #ffffff; }
  .quote-list .quote-delivery .btn-save {
    border: 1px solid var(--highlight);
    box-sizing: border-box;
    border-radius: 8px;
    color: #fff;
    font-size: 15px;
    line-height: 18px; }
    .quote-list .quote-delivery .btn-save:hover {
      color: #fff;
      background: var(--highlight);
      border: 1px solid var(--highlight); }

.quote-list .btn {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: var(--faded);
  border: 1px solid var(--faded);
  box-sizing: border-box;
  border-radius: 8px; }
  .quote-list .btn.active, .quote-list .btn:hover {
    color: #fff;
    background: var(--ok);
    border: 1px solid var(--ok); }
  .quote-list .btn.text-small {
    font-size: 14px;
    line-height: 17px; }
  .quote-list .btn.btn-button {
    color: #fff;
    background: var(--ok);
    border: 1px solid var(--ok); }

.quote-list .form-control.text-input {
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--ok);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fff; }
  .quote-list .form-control.text-input:focus {
    outline: none;
    box-shadow: none; }

.quote-list input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  cursor: pointer;
  display: block;
  width: 8px;
  color: #333;
  text-align: center;
  position: relative; }

.quote-list input[type="number"]:hover::-webkit-inner-spin-button {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAASCAQAAAAnfOz+AAAAd0lEQVQY032QQRGAMAwEDwYBkVApSAEl4AScIAEJlVAcLI8OtJTCvjL7yCUn3bDoDQMwlNLwQMCeeiIy59JxEXBZGIntkj1P+qh9oXepwzS+z22bo/KDSWIvlvi/SIktk6kZHOHWLg+ZK89LGAHwRVUfxUoSa5pPs4Go8/DBoZUAAAAASUVORK5CYII=) no-repeat 100%;
  width: 10px;
  height: 30px;
  position: relative;
  right: 0px;
  border-radius: 0; }

.quote-list .form-group.active.disabled {
  opacity: 0.6; }

.quote-list .search-bar {
  background: #ffffff;
  border-radius: 9px;
  height: 40px; }
  .quote-list .search-bar i.fa.fa-search {
    color: var(--ok); }

.saved-quote {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px; }
  .saved-quote .table {
    min-height: 56vh; }
  .saved-quote .table {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    color: #fff;
    font-size: 17px;
    line-height: 21px;
    cursor: pointer; }
    .saved-quote .table .thead {
      color: var(--highlight); }
    .saved-quote .table .details {
      color: var(--faded); }
    .saved-quote .table .express {
      position: relative;
      right: 30%; }
    .saved-quote .table .icon-express {
      width: 22px;
      height: 16px;
      background-image: url(/static/media/express.b80d1fe5.svg);
      background-size: 22px 16px; }

.quote-detail,
.quote-job {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #ffffff; }
  .quote-detail .id,
  .quote-job .id {
    font-size: 31px;
    line-height: 37px; }
  .quote-detail .quote,
  .quote-job .quote {
    font-size: 19px;
    line-height: 23px; }
  .quote-detail .order-details,
  .quote-detail .job-details,
  .quote-job .order-details,
  .quote-job .job-details {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: var(--faded); }
  .quote-detail .data,
  .quote-job .data {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--faded); }
    .quote-detail .data .value,
    .quote-job .data .value {
      font-size: 20px;
      line-height: 29px;
      color: #fff; }
      .quote-detail .data .value.expire,
      .quote-job .data .value.expire {
        color: var(--error); }
  .quote-detail .delivery-method,
  .quote-job .delivery-method {
    font-size: 26px;
    line-height: 31px;
    text-transform: capitalize; }
    .quote-detail .delivery-method .icon-express,
    .quote-job .delivery-method .icon-express {
      width: 41px;
      height: 30px;
      background-image: url(/static/media/express.b80d1fe5.svg);
      background-size: 41px 30px; }
    .quote-detail .delivery-method .icon-standard,
    .quote-job .delivery-method .icon-standard {
      width: 41px;
      height: 30px;
      background-image: url(/static/media/standard.6638e776.svg);
      background-size: 41px 30px; }
  .quote-detail .delivery-date .estimated,
  .quote-job .delivery-date .estimated {
    font-size: 12px;
    line-height: 15px; }
  .quote-detail .delivery-date .date,
  .quote-job .delivery-date .date {
    font-size: 26px;
    line-height: 31px; }
  .quote-detail .btn-cancel,
  .quote-job .btn-cancel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: var(--faded);
    border: 1px solid var(--error);
    border-radius: 8px; }
    .quote-detail .btn-cancel:focus,
    .quote-job .btn-cancel:focus {
      box-shadow: none; }
    .quote-detail .btn-cancel:hover,
    .quote-job .btn-cancel:hover {
      background-color: var(--error);
      color: #ffffff; }
  .quote-detail .details,
  .quote-detail .job-details,
  .quote-job .details,
  .quote-job .job-details {
    font-size: 14px;
    line-height: 17px;
    color: var(--faded); }
    .quote-detail .details .price,
    .quote-detail .job-details .price,
    .quote-job .details .price,
    .quote-job .job-details .price {
      font-size: 17px;
      line-height: 21px; }
    .quote-detail .details .order-price,
    .quote-detail .job-details .order-price,
    .quote-job .details .order-price,
    .quote-job .job-details .order-price {
      font-size: 40px;
      font-weight: 900;
      line-height: 60px;
      padding-left: 10px;
      color: #fff; }
    .quote-detail .details .total,
    .quote-detail .job-details .total,
    .quote-job .details .total,
    .quote-job .job-details .total {
      font-size: 17px; }
  .quote-detail .title,
  .quote-job .title {
    font-size: 31px;
    line-height: 37px; }
  .quote-detail .table,
  .quote-job .table {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #fff; }
    .quote-detail .table .thead,
    .quote-job .table .thead {
      font-size: 17px;
      line-height: 21px;
      color: var(--highlight); }
    .quote-detail .table .job,
    .quote-job .table .job {
      cursor: pointer; }
    .quote-detail .table .circle,
    .quote-job .table .circle {
      display: inline-block;
      width: 21px;
      height: 21px;
      background: var(--faded);
      border-radius: 50%; }
      .quote-detail .table .circle.active,
      .quote-job .table .circle.active {
        background-color: var(--ok);
        background-image: url(/static/media/check-icon.10e4d33b.svg);
        background-size: 12px 10px;
        background-position: center;
        background-repeat: no-repeat; }
        .quote-detail .table .circle.active.expire,
        .quote-job .table .circle.active.expire {
          background-color: var(--error);
          background-image: url(/static/media/expire-icon.7a296537.svg);
          background-position: center;
          background-repeat: no-repeat; }
  .quote-detail .circle,
  .quote-job .circle {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: var(--faded);
    border-radius: 50%; }
    .quote-detail .circle.active,
    .quote-job .circle.active {
      background-color: var(--ok);
      background-image: url(/static/media/check-icon.10e4d33b.svg);
      background-size: 21px 18px;
      background-position: center;
      background-repeat: no-repeat; }
      .quote-detail .circle.active.expire,
      .quote-job .circle.active.expire {
        background-color: var(--error);
        background-image: url(/static/media/expire-icon.7a296537.svg);
        background-position: center;
        background-repeat: no-repeat; }
  .quote-detail .slider .circle,
  .quote-job .slider .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--faded);
    background-image: none; }
    .quote-detail .slider .circle.active,
    .quote-job .slider .circle.active {
      background-color: var(--ok); }
      .quote-detail .slider .circle.active.expire,
      .quote-job .slider .circle.active.expire {
        background-color: var(--error);
        background-image: none; }
  .quote-detail .btn-save,
  .quote-job .btn-save {
    border: 1px solid var(--highlight);
    box-sizing: border-box;
    border-radius: 8px;
    color: #fff;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px; }
    .quote-detail .btn-save:hover, .quote-detail .btn-save:active,
    .quote-job .btn-save:hover,
    .quote-job .btn-save:active {
      background-color: var(--highlight); }
    .quote-detail .btn-save:focus,
    .quote-job .btn-save:focus {
      box-shadow: none; }

.pagination .page-item.active .page-link {
  background-color: transparent;
  color: var(--highlight); }

.pagination .page-link {
  border: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin: 0 10px;
  padding: 0; }
  .pagination .page-link.side, .pagination .page-link.side:hover {
    background-color: transparent;
    margin: 0 40px; }
  .pagination .page-link:hover {
    color: var(--highlight);
    background-color: transparent; }

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px; }

.switch input {
  opacity: 0;
  width: 20px;
  height: 20px; }

.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--faded);
  transition: 0.4s;
  border-radius: 20px; }
  .toggle:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 0px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s; }

input:checked + .toggle {
  background-color: var(--ok); }

input:focus + .toggle {
  box-shadow: 0 0 1px var(--faded); }

input:checked + .toggle:before {
  transform: translateX(20px); }

.modal.fade.hide {
  display: none; }

.modal.fade.show {
  display: block; }

.modal-backdrop {
  z-index: 1; }

.modal-content {
  background: var(--dark);
  border: 1px solid var(--highlight);
  box-sizing: border-box;
  border-radius: 7px;
  z-index: 10; }

.modal-header,
.modal-footer {
  border: none; }

.modal-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 31px;
  line-height: 37px;
  color: #fff; }

.modal-body .details {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff; }

.modal-body .form-control,
.modal-body .form-control:focus {
  border: 0;
  border-bottom: 1px solid var(--faded);
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  height: 35px;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  color: #fff; }

.modal-footer .btn-cancel {
  color: var(--faded);
  border: 1px solid var(--error);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px; }
  .modal-footer .btn-cancel:focus {
    outline: none; }
  .modal-footer .btn-cancel:hover {
    background-color: var(--error);
    border: 1px solid var(--error); }

.modal-footer .btn-save {
  border: 1px solid var(--highlight);
  box-sizing: border-box;
  border-radius: 8px;
  color: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px; }
  .modal-footer .btn-save:hover {
    background-color: var(--highlight); }

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .quotes-section .btn,
  .quotes-section .label-checkbox {
    font-size: 17px; }
  .quotes-section .form-control.select,
  .quotes-section .form-control.select-icon {
    font-size: 16px; }
  .quotes-section .quote-delivery .text {
    font-size: 13px; }
  .quotes-section .quote-delivery .date {
    font-size: 22px; } }

@media screen and (max-width: 766px) {
  .quote-list .submenu .submenu-tab.active,
  .quote-list .submenu .submenu-tab:hover {
    border-bottom: none; }
  .quote-detail .circle {
    display: inline-block;
    width: 23px;
    height: 23px; }
  .quote-detail .block-bar .circle {
    width: 30px;
    height: 30px; }
    .quote-detail .block-bar .circle.active {
      background-size: 10px 12px; }
  .quote-detail .block-bar .line {
    width: 50px;
    top: -13px; }
  .quote-detail .status {
    width: 82px;
    font-size: 11px;
    line-height: 13px; }
  .quote-detail .delivery-method .icon-express {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/express.b80d1fe5.svg);
    background-size: 27px 29px; }
  .quote-detail .delivery-method .icon-standard {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/standard.6638e776.svg);
    background-size: 27px 29px; }
  .quote-job .block-bar .circle {
    width: 20px;
    height: 20px; }
    .quote-job .block-bar .circle.active {
      background-size: 10px 12px; }
  .quote-job .block-bar .line {
    width: 50px;
    top: -9px; }
  .quote-job .status {
    width: 72px; }
  .quote-job .delivery-method .icon-express {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/express.b80d1fe5.svg);
    background-size: 27px 29px; }
  .quote-job .delivery-method .icon-standard {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/standard.6638e776.svg);
    background-size: 27px 29px; }
  .table,
  .thead {
    font-size: 14px; }
  .quote-list .submenu .submenu-tab.active,
  .quote-list .submenu .submenu-tab:hover {
    border-bottom: none; }
  .quote-detail .id {
    font-size: 26px; }
  .quote-detail .circle {
    display: inline-block;
    width: 23px;
    height: 23px; }
  .quote-detail .block-bar .circle {
    width: 30px;
    height: 30px; }
    .quote-detail .block-bar .circle.active {
      background-size: 10px 12px; }
  .quote-detail .block-bar .line {
    width: 50px;
    top: -13px; }
  .quote-detail .status {
    width: 82px;
    font-size: 11px;
    line-height: 13px; }
  .quote-detail .delivery-method .icon-express {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/express.b80d1fe5.svg);
    background-size: 27px 29px; }
  .quote-detail .delivery-method .icon-standard {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/standard.6638e776.svg);
    background-size: 27px 29px; }
  .quote-job .block-bar .line {
    width: 50px;
    top: -13px; }
  .quote-job .status {
    width: 82px;
    font-size: 11px; }
  .quote-job .delivery-method .icon-express {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/express.b80d1fe5.svg);
    background-size: 27px 29px; }
  .quote-job .delivery-method .icon-standard {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/standard.6638e776.svg);
    background-size: 27px 29px; } }

@media only screen and (min-width: 767px) and (max-width: 1279px) {
  .table,
  .thead {
    font-size: 14px; }
  .quote-detail .table {
    font-size: 14px; }
    .quote-detail .table .thead {
      font-size: 14px; }
  .quote-detail .id {
    font-size: 26px; }
  .quote-detail .circle {
    display: inline-block;
    width: 23px;
    height: 23px; }
  .quote-detail .block-bar .circle {
    width: 30px;
    height: 30px; }
    .quote-detail .block-bar .circle.active {
      background-size: 10px 12px; }
  .quote-detail .block-bar .line {
    width: 50px;
    top: -13px; }
  .quote-detail .status {
    width: 82px;
    font-size: 11px;
    line-height: 13px; }
  .quote-detail .delivery-method .icon-express {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/express.b80d1fe5.svg);
    background-size: 27px 29px; }
  .quote-detail .delivery-method .icon-standard {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/standard.6638e776.svg);
    background-size: 27px 29px; }
  .quote-job .block-bar .line {
    width: 50px;
    top: -13px; }
  .quote-job .status {
    width: 80px;
    font-size: 11px; }
  .quote-job .delivery-method .icon-express {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/express.b80d1fe5.svg);
    background-size: 27px 29px; }
  .quote-job .delivery-method .icon-standard {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/standard.6638e776.svg);
    background-size: 27px 29px; } }

.section-error p {
  color: white; }

.table {
  font-size: 16px;
  line-height: 21px;
  color: #fff; }
  .table .thead {
    font-size: 16px;
    line-height: 21px;
    color: var(--highlight); }
  .table .job {
    cursor: pointer; }
    .table .job:hover svg path {
      fill: var(--highlight); }
    .table .job:hover .button-edit.expire svg path {
      fill: var(--error); }
  .table .details {
    color: var(--faded); }
  .table .button-edit {
    background-color: transparent;
    border: none; }
    .table .button-edit.expire path {
      fill: var(--error); }
  .table .details-production,
  .table .details-production a {
    color: var(--faded); }
  .table .expire {
    color: var(--error); }

.orders-section .submenu {
  background: var(--base);
  margin-top: -7px; }
  .orders-section .submenu .submenu-tab {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: var(--faded);
    background-color: transparent;
    border: none; }
  .orders-section .submenu .submenu-tab.active,
  .orders-section .submenu .submenu-tab:hover {
    color: #FFFFFF;
    border-bottom: 2px solid var(--highlight);
    font-weight: 600;
    transition: color 0.3s; }
  .orders-section .submenu .btn-back {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: var(--faded);
    background-color: transparent;
    border: none; }
    .orders-section .submenu .btn-back:hover {
      color: var(--ok); }
      .orders-section .submenu .btn-back:hover svg path {
        fill: var(--ok); }
  .orders-section .submenu .badge {
    position: relative;
    top: -10px;
    left: 3px;
    height: 16px;
    width: 16px;
    font-size: 10px;
    line-height: 12px; }

.orders-section .search-bar {
  background: #FFFFFF;
  border-radius: 9px;
  height: 40px; }
  .orders-section .search-bar i.fa.fa-search {
    color: var(--ok); }

.orders-section .order-detail,
.orders-section .order-job,
.orders-section .order-artwork,
.orders-section .order-tracking {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #FFFFFF; }
  .orders-section .order-detail .id,
  .orders-section .order-job .id,
  .orders-section .order-artwork .id,
  .orders-section .order-tracking .id {
    font-size: 31px;
    line-height: 37px; }
  .orders-section .order-detail .quote,
  .orders-section .order-job .quote,
  .orders-section .order-artwork .quote,
  .orders-section .order-tracking .quote {
    font-size: 19px;
    line-height: 23px; }
  .orders-section .order-detail .order-details,
  .orders-section .order-detail .job-details,
  .orders-section .order-job .order-details,
  .orders-section .order-job .job-details,
  .orders-section .order-artwork .order-details,
  .orders-section .order-artwork .job-details,
  .orders-section .order-tracking .order-details,
  .orders-section .order-tracking .job-details {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: var(--faded); }
  .orders-section .order-detail .data,
  .orders-section .order-job .data,
  .orders-section .order-artwork .data,
  .orders-section .order-tracking .data {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--faded); }
  .orders-section .order-detail .value,
  .orders-section .order-job .value,
  .orders-section .order-artwork .value,
  .orders-section .order-tracking .value {
    font-size: 22px;
    line-height: 29px;
    color: #fff; }
    .orders-section .order-detail .value.expire,
    .orders-section .order-job .value.expire,
    .orders-section .order-artwork .value.expire,
    .orders-section .order-tracking .value.expire {
      color: var(--error); }
  .orders-section .order-detail .delivery-method,
  .orders-section .order-job .delivery-method,
  .orders-section .order-artwork .delivery-method,
  .orders-section .order-tracking .delivery-method {
    font-size: 26px;
    line-height: 31px;
    text-transform: capitalize; }
    .orders-section .order-detail .delivery-method .icon-express,
    .orders-section .order-job .delivery-method .icon-express,
    .orders-section .order-artwork .delivery-method .icon-express,
    .orders-section .order-tracking .delivery-method .icon-express {
      width: 41px;
      height: 30px;
      background-image: url(/static/media/express.b80d1fe5.svg);
      background-size: 41px 30px; }
    .orders-section .order-detail .delivery-method .icon-standard,
    .orders-section .order-job .delivery-method .icon-standard,
    .orders-section .order-artwork .delivery-method .icon-standard,
    .orders-section .order-tracking .delivery-method .icon-standard {
      width: 41px;
      height: 30px;
      background-image: url(/static/media/standard.6638e776.svg);
      background-size: 41px 30px; }
  .orders-section .order-detail .delivery-date .estimated,
  .orders-section .order-job .delivery-date .estimated,
  .orders-section .order-artwork .delivery-date .estimated,
  .orders-section .order-tracking .delivery-date .estimated {
    font-size: 12px;
    line-height: 15px; }
  .orders-section .order-detail .delivery-date .date,
  .orders-section .order-job .delivery-date .date,
  .orders-section .order-artwork .delivery-date .date,
  .orders-section .order-tracking .delivery-date .date {
    font-size: 26px;
    line-height: 31px; }
  .orders-section .order-detail .details,
  .orders-section .order-detail .job-details,
  .orders-section .order-job .details,
  .orders-section .order-job .job-details,
  .orders-section .order-artwork .details,
  .orders-section .order-artwork .job-details,
  .orders-section .order-tracking .details,
  .orders-section .order-tracking .job-details {
    font-size: 14px;
    line-height: 17px;
    color: var(--faded); }
    .orders-section .order-detail .details .price,
    .orders-section .order-detail .job-details .price,
    .orders-section .order-job .details .price,
    .orders-section .order-job .job-details .price,
    .orders-section .order-artwork .details .price,
    .orders-section .order-artwork .job-details .price,
    .orders-section .order-tracking .details .price,
    .orders-section .order-tracking .job-details .price {
      font-size: 18px;
      line-height: 22px; }
    .orders-section .order-detail .details .order-price,
    .orders-section .order-detail .job-details .order-price,
    .orders-section .order-job .details .order-price,
    .orders-section .order-job .job-details .order-price,
    .orders-section .order-artwork .details .order-price,
    .orders-section .order-artwork .job-details .order-price,
    .orders-section .order-tracking .details .order-price,
    .orders-section .order-tracking .job-details .order-price {
      font-size: 40px;
      line-height: 37px;
      color: #fff; }
  .orders-section .order-detail .title,
  .orders-section .order-job .title,
  .orders-section .order-artwork .title,
  .orders-section .order-tracking .title {
    font-size: 31px;
    line-height: 37px; }
  .orders-section .order-detail .table,
  .orders-section .order-job .table,
  .orders-section .order-artwork .table,
  .orders-section .order-tracking .table {
    font-weight: 600; }
    .orders-section .order-detail .table .thead,
    .orders-section .order-job .table .thead,
    .orders-section .order-artwork .table .thead,
    .orders-section .order-tracking .table .thead {
      color: var(--ok); }
    .orders-section .order-detail .table .circle,
    .orders-section .order-job .table .circle,
    .orders-section .order-artwork .table .circle,
    .orders-section .order-tracking .table .circle {
      display: inline-block;
      width: 21px;
      height: 21px;
      background: var(--faded);
      border-radius: 50%; }
      .orders-section .order-detail .table .circle.active,
      .orders-section .order-job .table .circle.active,
      .orders-section .order-artwork .table .circle.active,
      .orders-section .order-tracking .table .circle.active {
        background-color: var(--ok);
        background-image: url(/static/media/check-icon.10e4d33b.svg);
        background-size: 12px 10px;
        background-position: center;
        background-repeat: no-repeat; }
        .orders-section .order-detail .table .circle.active.expire,
        .orders-section .order-job .table .circle.active.expire,
        .orders-section .order-artwork .table .circle.active.expire,
        .orders-section .order-tracking .table .circle.active.expire {
          background-color: var(--error);
          background-image: url(/static/media/expire-icon.7a296537.svg);
          background-position: center;
          background-repeat: no-repeat; }
  .orders-section .order-detail .circle,
  .orders-section .order-job .circle,
  .orders-section .order-artwork .circle,
  .orders-section .order-tracking .circle {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: var(--faded);
    border-radius: 50%; }
    .orders-section .order-detail .circle.active,
    .orders-section .order-job .circle.active,
    .orders-section .order-artwork .circle.active,
    .orders-section .order-tracking .circle.active {
      background-color: var(--ok);
      background-image: url(/static/media/check-icon.10e4d33b.svg);
      background-size: 21px 18px;
      background-position: center;
      background-repeat: no-repeat; }
      .orders-section .order-detail .circle.active.expire,
      .orders-section .order-job .circle.active.expire,
      .orders-section .order-artwork .circle.active.expire,
      .orders-section .order-tracking .circle.active.expire {
        background-color: var(--error);
        background-image: url(/static/media/expire-icon.7a296537.svg);
        background-position: center;
        background-repeat: no-repeat; }
  .orders-section .order-detail .slider .circle,
  .orders-section .order-job .slider .circle,
  .orders-section .order-artwork .slider .circle,
  .orders-section .order-tracking .slider .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--faded);
    background-image: none;
    margin: 0 2px; }
    .orders-section .order-detail .slider .circle.expire,
    .orders-section .order-job .slider .circle.expire,
    .orders-section .order-artwork .slider .circle.expire,
    .orders-section .order-tracking .slider .circle.expire {
      background-color: var(--error);
      background-image: none; }
    .orders-section .order-detail .slider .circle.default,
    .orders-section .order-job .slider .circle.default,
    .orders-section .order-artwork .slider .circle.default,
    .orders-section .order-tracking .slider .circle.default {
      background-color: var(--faded);
      background-image: none; }
    .orders-section .order-detail .slider .circle.active,
    .orders-section .order-job .slider .circle.active,
    .orders-section .order-artwork .slider .circle.active,
    .orders-section .order-tracking .slider .circle.active {
      background-color: var(--ok); }
  .orders-section .order-detail .page .react-pdf__Page__canvas,
  .orders-section .order-job .page .react-pdf__Page__canvas,
  .orders-section .order-artwork .page .react-pdf__Page__canvas,
  .orders-section .order-tracking .page .react-pdf__Page__canvas {
    max-width: 100%;
    text-align: center;
    overflow-x: hidden;
    height: auto !important; }

.order-list {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600; }
  .order-list table {
    font-size: 17px;
    line-height: 21px;
    color: #FFFFFF; }
  .order-list thead {
    color: var(--highlight); }
  .order-list .table {
    min-height: 56vh; }
  .order-list .block-bar {
    display: inline-block; }
    .order-list .block-bar .circle {
      display: inline-block;
      width: 21px;
      height: 21px;
      background: var(--faded);
      border-radius: 50%; }
      .order-list .block-bar .circle.active {
        background-color: var(--ok);
        background-image: url(/static/media/check-icon.10e4d33b.svg);
        background-size: 12px 10px;
        background-position: center;
        background-repeat: no-repeat; }
        .order-list .block-bar .circle.active.expire {
          background-color: var(--error);
          background-image: url(/static/media/expire-icon.7a296537.svg);
          background-position: center;
          background-repeat: no-repeat; }
    .order-list .block-bar .line {
      display: inline-block;
      width: 8px;
      height: 2px;
      background-color: var(--faded);
      position: relative;
      top: -9px; }
      .order-list .block-bar .line.active {
        background-color: var(--ok); }
  .order-list .icon-express {
    width: 22px;
    height: 16px;
    background-image: url(/static/media/express.b80d1fe5.svg);
    background-size: 22px 16px; }

.order-artwork {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600; }
  .order-artwork .time {
    font-size: 22px;
    line-height: 29px; }
    .order-artwork .time .expire {
      color: var(--error); }
  .order-artwork .form-control {
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--ok);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #fff; }
    .order-artwork .form-control:focus {
      outline: none;
      box-shadow: none; }
  .order-artwork .btn-save {
    border: 1px solid var(--highlight);
    box-sizing: border-box;
    border-radius: 8px;
    color: #fff;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px; }
    .order-artwork .btn-save:hover, .order-artwork .btn-save:active {
      background-color: var(--highlight); }
    .order-artwork .btn-save:focus {
      box-shadow: none; }
  .order-artwork .btn-reject {
    border: 1px solid var(--error);
    box-sizing: border-box;
    border-radius: 8px;
    color: #fff;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px; }
    .order-artwork .btn-reject:hover, .order-artwork .btn-reject:active {
      background-color: var(--error); }
    .order-artwork .btn-reject:focus {
      box-shadow: none; }
  .order-artwork .expired .expire {
    color: var(--error);
    font-size: 12px;
    line-height: 15px; }
  .order-artwork .expired .date-icon {
    background-color: transparent;
    border: none;
    position: relative;
    top: -35px; }
  .order-artwork .expired .date-picker .react-datepicker-wrapper {
    width: 100%; }
    .order-artwork .expired .date-picker .react-datepicker-wrapper .date-input {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 23px;
      color: #fff;
      padding: 10px;
      border-bottom: 2px solid var(--highlight); }
  .order-artwork .expired .date-picker .react-datepicker {
    background-color: #272856;
    border: 1px solid var(--highlight);
    box-sizing: border-box;
    border-radius: 8px;
    width: 93%; }
  .order-artwork .expired .date-picker .react-datepicker-popper,
  .order-artwork .expired .date-picker .react-datepicker__month-container {
    width: 100%; }
  .order-artwork .expired .date-picker .react-datepicker__header {
    background-color: transparent;
    width: 100%;
    padding: 8px 15px;
    border-bottom: 0; }
    .order-artwork .expired .date-picker .react-datepicker__header .react-datepicker__current-month {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 23px;
      color: #FFFFFF; }
    .order-artwork .expired .date-picker .react-datepicker__header .react-datepicker__day-names {
      display: flex;
      justify-content: space-around;
      margin: 15px 0 0; }
    .order-artwork .expired .date-picker .react-datepicker__header .react-datepicker__day-name {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 23px;
      color: var(--highlight); }
  .order-artwork .expired .date-picker .react-datepicker__navigation--next {
    background: url(/static/media/arrow-forward.e09b2a1a.svg) no-repeat;
    width: 20px;
    height: 20px;
    border: none; }
  .order-artwork .expired .date-picker .react-datepicker__navigation--previous {
    background: url(/static/media/arrow-backward.41ec322b.svg) no-repeat;
    width: 20px;
    height: 20px;
    border: none; }
  .order-artwork .expired .date-picker .react-datepicker__week {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-around; }
    .order-artwork .expired .date-picker .react-datepicker__week .react-datepicker__day--today {
      border-radius: 50%;
      background-color: var(--highlight);
      color: #fff;
      font-weight: 600; }
    .order-artwork .expired .date-picker .react-datepicker__week .react-datepicker__day {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      color: #fff;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .order-artwork .expired .date-picker .react-datepicker__week .react-datepicker__day:hover {
        border-radius: 50%;
        background-color: var(--highlight);
        color: #fff;
        font-weight: 600; }
    .order-artwork .expired .date-picker .react-datepicker__week .react-datepicker__day--disabled {
      color: var(--faded); }
      .order-artwork .expired .date-picker .react-datepicker__week .react-datepicker__day--disabled:hover {
        border-radius: 50%;
        background-color: #272856;
        color: var(--faded);
        font-weight: 600; }
    .order-artwork .expired .date-picker .react-datepicker__week .react-datepicker__day--selected {
      border-radius: 50%;
      background-color: var(--highlight); }
      .order-artwork .expired .date-picker .react-datepicker__week .react-datepicker__day--selected.react-datepicker__day--outside-month {
        color: #fff; }
    .order-artwork .expired .date-picker .react-datepicker__week .react-datepicker__day--outside-month {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      color: var(--faded); }
  .order-artwork .expired .date-picker .react-datepicker__triangle {
    display: none; }
  .order-artwork .expired .time {
    border-bottom: 2px solid var(--highlight); }
  .order-artwork .expired .time-icon {
    background-color: transparent;
    border: none;
    position: relative;
    top: -50px; }
  .order-artwork .artwork .status {
    position: relative;
    right: 13%;
    font-size: 22px;
    line-height: 29px; }
    .order-artwork .artwork .status .value {
      color: #fff; }
      .order-artwork .artwork .status .value.expire {
        color: var(--error); }
  .order-artwork .disabled .form-control {
    border-bottom: 1px solid var(--faded); }
  .order-artwork .disabled .btn-save,
  .order-artwork .disabled .btn-reject {
    border: 1px solid var(--faded);
    color: var(--faded); }
    .order-artwork .disabled .btn-save:active, .order-artwork .disabled .btn-save:hover,
    .order-artwork .disabled .btn-reject:active,
    .order-artwork .disabled .btn-reject:hover {
      background-color: transparent; }

@media screen and (max-width: 766px) {
  .orders-section .order-list .block-bar .circle {
    width: 16px;
    height: 16px; }
    .orders-section .order-list .block-bar .circle.active {
      background-size: 10px 8px; }
  .orders-section .order-list .block-bar .line {
    top: -7px; }
  .orders-section .order-detail .delivery-method .icon-express,
  .orders-section .order-artwork .time .delivery-method .icon-express,
  .orders-section .order-artwork .artwork .delivery-method .icon-express {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/express.b80d1fe5.svg);
    background-size: 27px 29px; }
  .orders-section .order-detail .delivery-method .icon-standard,
  .orders-section .order-artwork .time .delivery-method .icon-standard,
  .orders-section .order-artwork .artwork .delivery-method .icon-standard {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/standard.6638e776.svg);
    background-size: 27px 29px; }
  .orders-section .order-detail .circle.active,
  .orders-section .order-artwork .time .circle.active,
  .orders-section .order-artwork .artwork .circle.active {
    display: inline-block;
    width: 23px;
    height: 23px;
    background-size: 15px 15px; }
  .orders-section .order-detail .table .circle.active,
  .orders-section .order-artwork .time .table .circle.active,
  .orders-section .order-artwork .artwork .table .circle.active {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 2px; }
  .orders-section .order-detail .block-bar .circle,
  .orders-section .order-artwork .time .block-bar .circle,
  .orders-section .order-artwork .artwork .block-bar .circle {
    width: 30px;
    height: 30px; }
  .orders-section .order-detail .block-bar .line,
  .orders-section .order-artwork .time .block-bar .line,
  .orders-section .order-artwork .artwork .block-bar .line {
    width: 45px;
    top: -13px; }
  .orders-section .order-detail .status,
  .orders-section .order-artwork .time .status,
  .orders-section .order-artwork .artwork .status {
    width: 75px;
    font-size: 10px;
    line-height: 13px; }
  .orders-section .order-detail .mapboxgl-map,
  .orders-section .order-artwork .time .mapboxgl-map,
  .orders-section .order-artwork .artwork .mapboxgl-map {
    width: 250px;
    height: 185px; }
  .orders-section .order-job .id .circle.active,
  .orders-section .order-tracking .id .circle.active {
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-top: 3px; }
  .orders-section .order-job .cover-icon,
  .orders-section .order-tracking .cover-icon {
    width: 25px;
    height: 38px;
    margin-top: 20px; }
  .orders-section .order-job .delivery-method .icon-express,
  .orders-section .order-tracking .delivery-method .icon-express {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/express.b80d1fe5.svg);
    background-size: 27px 29px; }
  .orders-section .order-job .delivery-method .icon-standard,
  .orders-section .order-tracking .delivery-method .icon-standard {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/standard.6638e776.svg);
    background-size: 27px 29px; }
  .orders-section .order-job .block-bar .circle,
  .orders-section .order-tracking .block-bar .circle {
    width: 30px;
    height: 30px; }
  .orders-section .order-job .block-bar .line,
  .orders-section .order-tracking .block-bar .line {
    width: 37px;
    top: -13px; }
  .orders-section .order-job .status,
  .orders-section .order-tracking .status {
    width: 66px;
    font-size: 10px;
    line-height: 13px; }
  .orders-section .order-tracking .block-bar .circle {
    width: 30px;
    height: 30px; }
  .orders-section .order-tracking .block-bar .line {
    width: 30px;
    top: -13px; }
  .orders-section .order-tracking .status {
    width: 63px;
    font-size: 10px;
    line-height: 13px; }
    .orders-section .order-tracking .status .details {
      font-size: 10px; }
  .orders-section .order-tracking .mapboxgl-map {
    width: 250px;
    height: 185px; }
  .orders-section .order-detail .table {
    font-size: 14px; }
  .orders-section .order-detail .mapboxgl-map {
    width: 250px !important;
    height: 185px !important; }
  .orders-section .order-artwork .page .react-pdf__Page__canvas {
    max-width: 100%;
    text-align: center;
    overflow-x: hidden;
    width: 400px !important;
    height: auto !important; }
  .orders-section .page-link.side, .orders-section .page-link.side:hover {
    background-color: transparent;
    margin: 0 20px; } }

@media only screen and (min-width: 767px) and (max-width: 1279px) {
  .orders-section .order-list .block-bar .circle {
    width: 16px;
    height: 16px; }
    .orders-section .order-list .block-bar .circle.active {
      background-size: 10px 8px; }
  .orders-section .order-list .block-bar .line {
    top: -7px; }
  .orders-section .order-detail .delivery-method .icon-express,
  .orders-section .order-artwork .time .delivery-method .icon-express,
  .orders-section .order-artwork .artwork .delivery-method .icon-express {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/express.b80d1fe5.svg);
    background-size: 27px 29px; }
  .orders-section .order-detail .delivery-method .icon-standard,
  .orders-section .order-artwork .time .delivery-method .icon-standard,
  .orders-section .order-artwork .artwork .delivery-method .icon-standard {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/standard.6638e776.svg);
    background-size: 27px 29px; }
  .orders-section .order-detail .circle.active,
  .orders-section .order-artwork .time .circle.active,
  .orders-section .order-artwork .artwork .circle.active {
    display: inline-block;
    width: 23px;
    height: 23px;
    background-size: 15px 15px; }
  .orders-section .order-detail .table,
  .orders-section .order-artwork .time .table,
  .orders-section .order-artwork .artwork .table {
    font-size: 14px; }
    .orders-section .order-detail .table .thead,
    .orders-section .order-artwork .time .table .thead,
    .orders-section .order-artwork .artwork .table .thead {
      font-size: 14px; }
    .orders-section .order-detail .table .circle.active,
    .orders-section .order-artwork .time .table .circle.active,
    .orders-section .order-artwork .artwork .table .circle.active {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: 2px; }
  .orders-section .order-detail .block-bar .circle,
  .orders-section .order-artwork .time .block-bar .circle,
  .orders-section .order-artwork .artwork .block-bar .circle {
    width: 30px;
    height: 30px; }
  .orders-section .order-detail .block-bar .line,
  .orders-section .order-artwork .time .block-bar .line,
  .orders-section .order-artwork .artwork .block-bar .line {
    width: 45px;
    top: -13px; }
  .orders-section .order-detail .status,
  .orders-section .order-artwork .time .status,
  .orders-section .order-artwork .artwork .status {
    width: 75px;
    font-size: 10px;
    line-height: 13px; }
  .orders-section .order-job .table, .orders-section .order-job .thead,
  .orders-section .order-tracking .table,
  .orders-section .order-tracking .thead {
    font-size: 14px; }
  .orders-section .order-job .id .circle.active,
  .orders-section .order-tracking .id .circle.active {
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-top: 3px; }
  .orders-section .order-job .cover-icon,
  .orders-section .order-tracking .cover-icon {
    width: 25px;
    height: 38px;
    margin-top: 20px; }
  .orders-section .order-job .delivery-method .icon-express,
  .orders-section .order-tracking .delivery-method .icon-express {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/express.b80d1fe5.svg);
    background-size: 27px 29px; }
  .orders-section .order-job .delivery-method .icon-standard,
  .orders-section .order-tracking .delivery-method .icon-standard {
    width: 27px;
    height: 29px;
    background-image: url(/static/media/standard.6638e776.svg);
    background-size: 27px 29px; }
  .orders-section .order-job .block-bar .circle,
  .orders-section .order-tracking .block-bar .circle {
    width: 30px;
    height: 30px; }
  .orders-section .order-job .block-bar .line,
  .orders-section .order-tracking .block-bar .line {
    width: 37px;
    top: -13px; }
  .orders-section .order-job .status,
  .orders-section .order-tracking .status {
    width: 69px;
    font-size: 11px;
    line-height: 13px; }
  .orders-section .order-tracking .block-bar .circle {
    width: 30px;
    height: 30px; }
  .orders-section .order-tracking .block-bar .line {
    width: 50px;
    top: -13px; }
  .orders-section .order-tracking .status {
    width: 80px;
    font-size: 11px;
    line-height: 13px; }
  .orders-section .mapboxgl-map {
    width: 230px !important;
    height: 165px !important; } }


.block-bar {
  display: inline-block; }
  .block-bar .circle {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: var(--faded);
    border-radius: 50%; }
    .block-bar .circle.active {
      background-color: var(--ok);
      background-image: url(/static/media/check-icon.10e4d33b.svg);
      background-size: 21px 18px;
      background-position: center;
      background-repeat: no-repeat; }
      .block-bar .circle.active.expire {
        background-color: var(--error);
        background-image: url(/static/media/expire-icon.7a296537.svg);
        background-position: center;
        background-repeat: no-repeat; }
  .block-bar .line {
    display: inline-block;
    width: 70px;
    height: 2px;
    background-color: var(--faded);
    position: relative;
    top: -15px; }
    .block-bar .line.active {
      background-color: var(--ok); }

.status {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--faded);
  width: 100px; }
  .status.active {
    color: #fff; }
  .status.expire {
    color: var(--error); }

@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .bar-job .line {
    width: 100px; } }

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .bar-job .line {
    width: 80px; } }

@media screen and (max-width: 1200px) {
  .block-bar .line {
    width: 65px; }
  .status {
    width: 100px; } }


.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500; }
  .status-badge.status-0 {
    background-color: #f8f9fa;
    color: #6c757d; }
  .status-badge.status-1 {
    background-color: #cfe2ff;
    color: #0d6efd; }
  .status-badge.status-2 {
    background-color: #d1e7dd;
    color: #198754; }

.order-detail {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #FFFFFF; }
  .order-detail .id {
    font-size: 31px;
    line-height: 37px; }
  .order-detail .quote {
    font-size: 19px;
    line-height: 23px; }
  .order-detail .order-details,
  .order-detail .job-details {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: var(--faded); }
  .order-detail .data {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--faded); }
  .order-detail .value {
    font-size: 22px;
    line-height: 29px;
    color: #fff; }
    .order-detail .value.expire {
      color: var(--error); }
  .order-detail .delivery-method {
    font-size: 26px;
    line-height: 31px;
    text-transform: capitalize; }
    .order-detail .delivery-method .icon-express {
      width: 41px;
      height: 30px;
      background-image: url(/static/media/express.b80d1fe5.svg);
      background-size: 41px 30px; }
    .order-detail .delivery-method .icon-standard {
      width: 41px;
      height: 30px;
      background-image: url(/static/media/standard.6638e776.svg);
      background-size: 41px 30px; }
  .order-detail .delivery-date .estimated {
    font-size: 12px;
    line-height: 15px; }
  .order-detail .delivery-date .date {
    font-size: 26px;
    line-height: 31px; }
  .order-detail .details,
  .order-detail .job-details {
    font-size: 14px;
    line-height: 17px;
    color: var(--faded); }
    .order-detail .details .price,
    .order-detail .job-details .price {
      font-size: 18px;
      line-height: 22px; }
    .order-detail .details .order-price,
    .order-detail .job-details .order-price {
      font-size: 40px;
      line-height: 37px;
      color: #fff; }
  .order-detail .title {
    font-size: 31px;
    line-height: 37px; }
  .order-detail .table {
    font-weight: 600; }
    .order-detail .table .thead {
      color: var(--ok); }
    .order-detail .table .circle {
      display: inline-block;
      width: 21px;
      height: 21px;
      background: var(--faded);
      border-radius: 50%; }
      .order-detail .table .circle.active {
        background-color: var(--ok);
        background-image: url(/static/media/check-icon.10e4d33b.svg);
        background-size: 12px 10px;
        background-position: center;
        background-repeat: no-repeat; }
        .order-detail .table .circle.active.expire {
          background-color: var(--error);
          background-image: url(/static/media/expire-icon.7a296537.svg);
          background-position: center;
          background-repeat: no-repeat; }
  .order-detail .circle {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: var(--faded);
    border-radius: 50%; }
    .order-detail .circle.active {
      background-color: var(--ok);
      background-image: url(/static/media/check-icon.10e4d33b.svg);
      background-size: 21px 18px;
      background-position: center;
      background-repeat: no-repeat; }
      .order-detail .circle.active.expire {
        background-color: var(--error);
        background-image: url(/static/media/expire-icon.7a296537.svg);
        background-position: center;
        background-repeat: no-repeat; }
  .order-detail .slider .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--faded);
    background-image: none;
    margin: 0 2px; }
    .order-detail .slider .circle.expire {
      background-color: var(--error);
      background-image: none; }
    .order-detail .slider .circle.default {
      background-color: var(--faded);
      background-image: none; }
    .order-detail .slider .circle.active {
      background-color: var(--ok); }
  .order-detail .page .react-pdf__Page__canvas {
    max-width: 100%;
    text-align: center;
    overflow-x: hidden;
    height: auto !important; }

.order-tracking .status {
  font-size: 11px;
  line-height: 13px; }

.order-detail .table {
  font-size: 16px;
  line-height: 21px;
  color: #fff; }
  .order-detail .table .thead {
    font-size: 16px;
    line-height: 21px;
    color: var(--highlight); }
  .order-detail .table .job {
    cursor: pointer; }
    .order-detail .table .job:hover svg path {
      fill: var(--highlight); }
    .order-detail .table .job:hover .button-edit.expire svg path {
      fill: var(--error); }
  .order-detail .table .details {
    color: var(--faded); }
  .order-detail .table .button-edit {
    background-color: transparent;
    border: none; }
    .order-detail .table .button-edit.expire path {
      fill: var(--error); }
  .order-detail .table .details-production,
  .order-detail .table .details-production a {
    color: var(--faded); }
  .order-detail .table .expire {
    color: var(--error); }

@media only screen and (min-width: 767px) and (max-width: 1279px) {
  .order-detail .table {
    font-size: 14px; }
    .order-detail .table .thead {
      font-size: 14px; }
    .order-detail .table .circle.active {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: 2px; } }

.col-1-5 {
  width: 12.5%; }

.text-faded {
  color: var(--faded); }

