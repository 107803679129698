.loaderApp {
    position:absolute;
    width:100%;
    height:100%;
    .spinContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
}