/* styles */
body {
    background: linear-gradient(180deg, var(--dark) 0%, var(--dark) 59.9%, var(--base) 100%) 100%;
    background-attachment: fixed;
    font-family: Montserrat;
}

.error {
    color: var(--error);
    font-size: 14px;
}
.blue-notification {
    color:#08b1fb;
}

.logo img{
    width:220px;
    margin-left: -10px;
}

.text-logo {
    font-family: 'SF New Republic';
    font-style: normal;
    font-weight: normal;
    font-size: 65px;
    line-height: 57px;
    letter-spacing: -0.05em;
    color: #FFFFFF;
    position: relative;
    right: 50px;
    top: 40px;

    span {
        font-family: Montserrat;
        font-weight: normal;
    }
}

.hello-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 78px;
    color: #ffffff;
}

.field-label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
}

input,
input:focus {
    outline: none;
    border: 0;
    background-color: transparent;
    color: var(--faded);
}

.form-button {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    background-color: transparent;
    border: 1px solid var(--highlight);
    border-radius: 6px;
    height: 45px;
    width: 100%;
}

.form-button.active,
.form-button.active:hover,
.form-button:hover {
    background: var(--highlight);
    border-radius: 7px;
    color: #fff;
}

.form-forgot {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--faded);
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active  {
//     -webkit-box-shadow: 0 0 0 30px var(--dark) inset !important;
//     -webkit-text-fill-color: var(--faded) !important;
// }

.rc-anchor-light {
    background: transparent !important;
    border: 1px solid var(--faded) !important;
}

.login-section,
.register-section,
.forgot-section {

    .form-control {
        border: 0;
        border-bottom: 1px solid var(--highlight);
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        height: 46px;
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;
        color: #fff;

        &:focus {
            font-weight: 600;
        }
    }
}

.error-check-user {
    p {
        color: white
    }
}