.jobs-chart {
    .chart-bar {
        display: flex;
        transform: rotateX(180deg);
        height: 122px;

        .bar {
            position:relative;
            width: 28px;
            margin-left: 5px;
            background: var(--faded);
            display: inline-block;
            transition:all 0.3s ease-in-out;

            &:hover {
                cursor: pointer;
            }
            .counter-block {
                opacity:0;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                transform: rotateX(180deg);
                position: absolute;
                color: white;
                top: 100%;
                transition:all 0.3s ease-in-out;
            }
            &:hover {
                .counter-block {
                    opacity:1
                }
                background: var(--highlight);
                width: 65px;
                text-align: center;
            }
            &.activeMonth {
                background: var(--highlight);
                width: 65px;
                .counter-block {
                    opacity:1
                }
            }
            &.currentMonth {
                width: 65px;
                background: var(--highlight);

                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    transform: rotateX(180deg);
                    position: absolute;
                    color: white;
                    top: 100%;
                }
            }
        }
    }
    .chart-month {
        font-family: Montserrat;

        div {
            width: 28px;
            margin-left: 5px;
            display: inline-block;
            font-weight:600;
            font-size: 18px;
            line-height: 22px;
            color: var(--faded);
            transition:all 0.3s ease-in-out;
            text-align: center;

            &:hover {
                color: #FFFFFF;
                // width: 65px;
                text-align: center;
            }
            &.activeMonthText {
                color: #FFFFFF;
                width: 65px;
                cursor: pointer;
            }
            &.active {
                color: #FFFFFF;
                font-weight:600;
                font-size: 18px;
                line-height: 22px;
                width: 65px;
                text-align: center;
            }
        }
    }

    .total-number {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 64.6445px;
        line-height: 79px;
        color: #FFFFFF;
        cursor: pointer;
    }
}